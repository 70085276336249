.sp-container{
    background-color: #002d3a;
    padding: 20px 30px;
    /* width: 100%; */
}

.lpm-part-2 p{
    margin-bottom: 8px;
}
.sp-left-content{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap:30px
}
 .form-select.custom-arrow {
    appearance: none; /* Hides the default arrow */
    /* height: 50px; Adjust height as needed */
    background-image: url('../Assets/arrow-down.png'); /* Path to custom arrow icon */
    background-repeat: no-repeat;
    background-position: right 8px center;
    color: #ffffff50; /* Adjust based on arrow size */
    background-size: 20px; /* Size of the custom arrow */
    padding-right: 2rem;
 /* Space for the custom arrow */
  }
  
  .form-select.custom-arrow:focus {
    outline: none; /* Removes the default focus outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional focus effect */
  }
  
.sp-body-container{
    display: flex;
    padding: 20px 0px;
    gap: 30px;
    justify-content: space-between;
}
.back-arrow{
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    cursor: pointer;
}
.back-arrow img{
    /* border:1px solid red; */
    padding: 5px;
}
.back-arrow p{
    /* border: 1px solid red; */
padding-top: 16px;
font-size: 16px;
font-weight: 500;
}
.sp-main-portion{
    background-color: #003d4e;
    width: 100%;
    border-radius: 10px;
}

.sp-part-1{
    /* border-bottom: 1px solid #005771; */
    padding:24px 12px;
    text-align: center;
    border-radius: 30px;
    /* border: 1px solid #005771; */
    /* width: 70%; */

}

.sp-part-1-head{
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 24px;
    color: white;
    margin-bottom: 16px;
}

.sp-part-1-head span{
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 24px;
    font-style: italic;
    color: #00dbbf;
}

.sp-part-1-allbuttons{
    width:100%;
    display: flex;
    border: 2px solid #005771;
    padding: 16px ;
    /* margin: auto; */
    /* margin-left: 114px; */
    /* margin-right: 114px; */
    background-color: #002d3a;
    gap: 16px;
    /* color: #00dbbf; */
    border-radius: 62px;
    justify-content: space-between;
    /* width: 90%; */
    border-radius: 62px;
border: 2px solid rgba(255, 255, 255, 0.15);
background: rgba(0, 45, 58, 0.50);
backdrop-filter: blur(5px);
}

.sp-part-1-btn{
    border-radius: 45px;
    padding: 10px;
    align-items: center;
    color: #00dbbf;
    background-color: #003b4c;
    border: none;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    pointer-events: auto;
}

.sp-part-1-apply-btn:hover{
    background-color: transparent;
    border: 1px solid #00dbbf;
    color: #00dbbf;
}

.sp-part-1-input{
    border-radius: 45px;
    padding: 10px;
    align-items: center;
    color: #00dbbf;
    background-color: #003b4c;
    border: none;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    pointer-events: auto;
    width:min-content
}


.sp-part-1-apply-btn{
    background-color: #00dbbf;
    color: #003b4c;
    border: none;
    padding: 8px 24px;
    border-radius: 44px;
}
  .sp-part-1-btn:active,
  .sp-part-1-btn:focus {
    background-color: transparent;
    outline: 1.9px solid #00dbbf; /* Add a visible outline to show active/focus state */
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Optional for extra visual effect */
  }

  .sp-part-1-input:active,
  .sp-part-1-input:focus {
    background-color: transparent;
    outline: 1.9px solid #00dbbf; /* Add a visible outline to show active/focus state */
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Optional for extra visual effect */
  }

  .sp-part-1-input::placeholder{
    text-align: center;
  }



.sp-list-sidebar {
    width: 30%;

    /* height: 700px; */
}
.sp-list-sidebar img{
width: 100%;
height: 70vh;
border-radius: 20px;
}

.sp-body-image{
    display: flex;
    flex-direction: column;
    gap:48px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
}
.sp-body-image p{
    font-size: x-large;
    font-weight: 600;
}
.sp-selling-women{
    width: 339px;
    height: 285px;
}

.sp-list-sidebar h1 {
    font-size: 60px;
    color: white;
}

.sp-list-sidebar .sp-get-started {
    margin-top: 50px;
    font-size: 30px;
    color: white;
    font-weight: bold;
}

.sp-rup {
    font-size: 90px;
    color: white;
    font-weight: bold;
}

.sp-list-sidebar p {
    font-size: 16px;
    color: white;
    text-align: end;
    margin-top: 0;
}

.sp-list-sidebar {
    width:100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    /* margin-bottom: 15px; */
}
.sp-buy-now {
    background-color: green;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    padding: 16px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
    text-align: center;
    transition: background-color 0.3s ease;
}
.sp-buy-now:hover {
    background-color: #0056b3;
}
 .sp-main-portion .form-select  {
    border-radius: 45px;
    text-align: center;
    background-color: #003B4C;
    border:none;
    outline: none;
    /* width:70%; */
    padding:10px 16px ;
    /* max-width:172px; */
    color:#ffffffcc;
    outline:none;
}
 .form-select option{
    color:rgba(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    letter-spacing: 1px;
}
.sp-main-portion .active{
    border:1px solid #00dbbf;
    color:#00dbbf;
}
.sp-advertisement{width:100%;padding:0;}
.sp-advertisement img{
    /* margin-top:150px 0 0 0; */
    width:100%;
height:auto;
/* transform: rotate(90deg);} */
}
.sel-prop-options{
    border-radius: 16px;
border: 2px solid rgba(255, 255, 255, 0.15);
background: rgba(0, 45, 58, 0.50);
backdrop-filter: blur(5px);
}
.map-container{

    padding: 16px 30px;
    background:transparent;
    
}
.GoogleMap{
    width:100%;
    height:400px;
}
/* --------------------------------------------------------------------------- */

@media only screen and (max-width: 320px) {
    .sp-selling-women{
        width:200px;
        height: 200px;
        margin-right: 56px;
    }
    
}


@media only screen and (min-width:321px) and (max-width:425px){
    .sp-selling-women{
        width:200px;
        height: 200px;
        margin-right: 56px;
    }
}

@media (max-width:1024px) {
    .lpm-part-2-first,.lpm-part-2-second-line,.lpm-part-4-body{
        flex-direction: column;
    }
    .lpm-part-2-1,.lpm-part-4-1-allbuttonss,.lpm-part-2-1-allbuttons
    {
        width:100%;
    }
    .lpm-part-2-11{
        width: 50%;
    }
    .sp-part-1-allbuttons{
        flex-wrap: wrap;
        gap:10px;
        justify-content: flex-start;
        border-radius: 0;
    }
}

@media (max-width:768px){
    .sp-part-1-allbuttons{
    justify-content: space-between;
    }     

}
@media (max-width:426px) {
    .sp-part-1-allbuttons{
        display: flex;
        flex-direction: column;
        width:100%;
    }
    .sp-part-1-allbuttons select,.sp-part-1-allbuttons input
        {width:100%}

}