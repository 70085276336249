.pa2-container{
  width: 100%;
  height: 100%;
  /* max-width: 328px; */
  background-color: rgba(0, 45, 58, 0.1);
  border: 1px solid #005771;
  border-radius: 30px;
  padding: 24px 0px;
  gap: 24px;
}

.pa2-head{
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  color: white;
  padding: 0px 16px 16px 16px;
  border-bottom: 1px solid #005771;
  display: flex;
  justify-content: space-evenly;
}

.pa2-upi{
  padding: 16px 16px;
  /* margin-bottom: 10px; */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
}

.pa2-upi-left, .pa2-upi-right{
  display: flex;
  gap: 10px;
}

.pa2-upi-right img{
  width: auto;
  height: 18px;
  
}

.pa2-input-1{
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #264c58;
  width: 94%;
  background-color: #004559;
}

.pa2-enter-upi{
  display: flex;
  align-items: center;
  padding: 0px 16px;
}

.pa2-enter-upi-img{
  color: white;
  margin-left: -22px;
}

.pa2-upi-left-2{
  padding: 16px 16px;
  display: flex;
  gap: 10px;
  color: white;
  letter-spacing: 1px;
}

.pa2-enter-card-main{
  align-items: center;
  padding: 0px 16px;
}
.pa2-enter-card{
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #264c58;
  width: 94%;
  background-color: #004559;
}
.pa2-icon{
  margin-left: -22px;
}
.pa2-cards{
  display: flex;
  padding: 20px 16px;
  gap: 10px;
}

.pa2-card-1{
  display: flex;
  align-items: center;
  color: white;
}

.pa2-card-input{
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #264c58;
  width: 88%;
  background-color: #004559;
}

.pa2-amount{
  padding: 8px 22px;
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
}

.pa2-amount-1{
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  line-height: 20px;
  height: 30px;
}

.pa2-amount-2{
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  line-height: 20px;
}

.pa2-proceed{
  padding: 0px 16px;
}
.pa2-proceed-btn{
  width: 100%;
  padding: 10px 24px;
  background-color: #00dbbf;
  border: none;
  color: #005771;
  font-size: 16px;
  /* font-weight: bold; */
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pa2-proceed-btn:hover {
  background-color: #00c48a;
}