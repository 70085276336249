/* YourStyles.css */

/* Target autofilled inputs */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  
  }
  
  input:-webkit-autofill::placeholder {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  
  }
  
  /* Other browsers */
  input:-moz-autofill {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  
  }
  
  input:-moz-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  
  }
  
  input:-moz-autofill::placeholder {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  }
  /* Autofill background and border for select elements */
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #002D3A inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: none; /* Match Bootstrap border color */
  }
  
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #003B4C inset; /* Change background color */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: 1px solid #005771; /* Match Bootstrap border color */
  }
  
  



.sf-cont{
    width:100%;
    height:auto;
min-height: 100vh;
    display: flex;
    flex-direction: row;
    /* gap:16px; */
    justify-content: space-between;
    padding:24px 40px;
}
.sf-cont2{
    width:100%;
    justify-content: center;
padding: 3% 10% 0 10% ;
/* border: 1px solid red; */
}
.sf-left-cont{
    width: 75%;
    margin-right:36px;
}
.sf-title-sec{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.sf-title-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}
.sf-title-right{
    display: flex;
    flex-direction:row;
    gap:20px;
}
.sf-title-right .form-select , .dropdown-option{
    min-width:266px;
}
.sf-title-right .form-select,.col .form-select{
    border-radius: 8px;
    text-align: left;
    background-color: #003B4C;
    border: none;
    outline: none;
    width: max-content;
    padding: 10px 16px;
    /* max-width: 192px; */
    color: #ffffffcc;
    outline: none;
}
.sf-bread-crumb{
    color: var(--Text-White, rgba(255, 255, 255, 0.70));
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
}

.row .col{
    display: flex;
    /* padding: var(--Spacing-Items-Space---8px, 8px) var(--Spacing-Items-Space---12px, 12px); */
    padding:0;
    align-items: center;
    gap: var(--Spacing-Items-Space---6px, 6px);
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Stroke, #005771);
    background: #003240;
    overflow: hidden;
}
.sf-2-piece .form-select , .col input , .col input::placeholder ,.col input:default,.col input:focus , .form-control,.form-control:focus ,.form-control::placeholder{
    /* border-radius: 45px; */
    /* cursor: pointer; */
    background-color: #003B4C;
    border:none;
    padding:10px 8px ;
    color:#ffffffcc;
    outline:none;
 
}
.form-control1{
    background-color: #003B4C;
    color:#ffffffcc;
    padding:0px 4px ;
    margin-left: 3px;
}


.sf-title-right .form-select option , .dropdown-options{
    /* border-radius:16px; */
}
/*.sf-2-piece*/ .form-select {
    width: 266px;
    height: 45px;
    white-space: nowrap; /* Prevents text wrapping */
    overflow: hidden;    /* Initially hides overflowed text */
    text-overflow: ellipsis; /* Adds '...' to indicate overflow */
    overflow-x: auto; 
    cursor: pointer;
       /* Enables horizontal scrolling */
}

.dropdown-options{
    background-color: #003B4C;
    list-style:none;
    padding:0;
    margin:0;
    height:max-content;
    max-height: 150px;
    overflow-y: auto;
    cursor: pointer;
    position: absolute;
}
.dropdown-option:hover{
    background-color: #888f92;
    /* color:#ffffffcc; */
}
/* Hide scrollbar for Webkit browsers */
.dropdown-options::-webkit-scrollbar ,.form-select::-webkit-scrollbar{
    display: none;
}

/* For IE, Edge, and Firefox */
.dropdown-options {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth; /* Enables smooth scrolling */
}
.mb-3{
    padding:0;
    margin-bottom:0;
    border-radius: 8px;
border: 1px solid var(--Stroke, #005771);
background: #003240;
}
.dropdown-options{
    margin-top:16px ;
    z-index: 99;
    border:1px solid #888f92;
    border-radius: 12px;
    padding:0;
    /* border: 1px solid #FFFFFFcc; */
    background-color:#003B4C;
}

 .col input:-webkit-autofill {
    background-color: hsl(194, 100%, 22%) !important;
    -webkit-text-fill-color: #FFFFFFcc !important; 
} 
.k .form-select option,.dropdown-option{
    color:rgba(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}
.dropdown-option{
    padding:8px 4px;
    font-weight: 300;

    /* border-bottom: 1px solid #FFFFFFcc; */
}
.sf-form-input , .sf-2-piece{
    padding:26px 0;
    display: flex;
    flex-direction: column;
    gap:24px;
}
.sf-2-piece{
    padding:0;
}
.row{
    display: flex;
    flex-direction: row;
    gap: 24px;
}
.form-control,.form-control1,.form-control:focus ,.form-control::placeholder{    
    border-radius: 8px; 
}
.row .col select{
    border-radius:0;
}
.add-image-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:24px;
}
.add-image-row label{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}
.salary-content{
    display: flex;
    flex-direction: row;
    gap:24px;
    flex-wrap: wrap;
    
  
}
.add-image-row input{
    border-radius: var(--radi-mlg, 8px);
    border: 1px dashed var(--Button, #00DBBF);
    padding:16px 36px 16px 8px;
    text-align: center;
    color:#ffffffcc;
    width:100%;
}
.sf-right-cont{
    width:100%;
    height:auto;
    border-radius: 30px;
    padding:16px; 
    border: 1px solid var(--Stroke, #005771);
    background: rgba(0, 61, 78, 0.90);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sf-right-top-cont{
    display:flex;
    flex-direction: column;
    gap:16px;
}
.sf-right-top-cont-1{
    display: flex;
    padding: 16px var(--spacing-lg, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border-bottom: 0.391px solid rgba(255, 255, 255, 0.30);

    background: linear-gradient(91deg, #00C9FF 0.06%, #92FE9D 96.8%);
    /* border: 2px solid red; */
    margin-bottom:16px;

}
.sf-top-cont-desc{
    color: var(--Background, #002D3A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}
.sf-right-top-cont-2{
    display: flex;
    flex-direction: column;
    background: rgba(0, 61, 78, 0.90);
    gap:16px;
    border-radius: 16px;
    border: 1px solid #264C58;
    border: 0.391px solid rgba(255, 255, 255, 0.30);
    padding: 0 16px 16px 16px;

}
.sf-right-top-cont-2-text{
    display: flex;
    flex-direction: column;
    gap:20px;
    padding:16px 12px;    
    border-radius: 12px 12px 0 0 ;
    background: rgba(0, 61, 78, 0.90);
    backdrop-filter: blur(5px);

}
.sf-right-top-cont-2-text1{
    color: var(--Button, #00DBBF);
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
}
.sf-right-top-cont-2-text2{
    color: var(--Text-White, rgba(255, 255, 255, 0.70));
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
}
.sf-right-top-cont-2-bills{
    border: 0.391px solid rgba(255, 255, 255, 0.30);
    border-radius:16px;
    padding:0 12px 12px 12px;
    height:auto;
    overflow-y: scroll;
}
.sf-right-top-cont-2-bills::-webkit-scrollbar{
    scroll-behavior: smooth;
    display: none;
}
.form-check{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.form-check input{
    width:24px;
    height:24px;
    border-radius: 8px;
    border: 1px solid var(--Button, #ffffffcc);
    background-color:transparent;
    /* border: 0.391px solid rgba(255, 255, 255, 0.30); */
}
.form-check-label {
    color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
padding:12px;
width:100%;
}
.form-check-label span{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
}
.sf-right-bottom-cont button{
    border-radius: 16px;
    width:100%;
    padding:12px 0 ;
    text-align: center;
}
.breadcrumb-item a ,.breadcrumb-item{
    color: #FFFFFFcc;
    text-decoration: none;
}
/* Target the separator between breadcrumb items */
.breadcrumb-item + .breadcrumb-item::before {
    content: '/'; 
    color: #FFFFFFcc; 
    
  }
  .sf-right-up{
    width:27%;
}
.col .form-select{
    width:100%;
}

  /* ------------------------------------------------ */
  @media (max-width:1024px){
    .sf-cont{
        flex-direction: column;
        width:100%;
    }
    .sf-left-cont{
        width: 100%;
    }
    .sf-right-up{
        width:100%;
    }
    .sf-right-cont{
        height: max-content;
        gap: 24px;
    }
    .form-select option{
        font-size: 12px;
    }
    .sf-right-top-cont{
        flex-direction: row;
        height:max-content;
    }
    .sf-right-top-cont-1{
        margin-bottom: 0;
  }
  .sf-title-left{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .sf-title-left .third-text{
    display:none;
  }
  .sf-title-sec{
    flex-direction:column;
    margin-bottom: 16px;

    /* justify */
  }
  .sf-title-right{
    width:100%;
  }
  .row .mb-3{
    width:100%;
  }
}

@media (max-width:426px) {
    .sf-cont{
        display: flex;
        flex-direction: column !important;
        width:100%;
        padding:16px;
        gap:16px;

    }
    .sf-right-up{
        width:100;
    }
    .sf-left-cont{
        width: 100%;
    }
    .sf-right-up{
        padding:10px;
        width:100%;
    }
    .sf-form-input ,.sf-2-piece ,.row ,.add-image-row{
        gap:8px;
    }
    .form-select{
        height: auto;
    }
    .sf-right-cont{
        height: max-content;
        gap: 24px;
    }
    .form-select option , .form-select{
        font-size: 11px;
    }
    .sf-right-top-cont{
        flex-direction: column;
        height:max-content;
    }
    .sf-right-top-cont-1{
        margin-bottom: 0;
  }   
  .sf-title-right{
    flex-direction: column;
    gap:8px;
  }
  .sf-title-left{
    font-size:10px;
  }
  .thir-text{
    float: left;
    width:100%;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
}