/* Layout container with a main section for services and a side ad */
.legal-services-layout-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding:  0 20px;
    /* background-color: #1C274C; */
    color: white;
}
.legal-services-layout-container2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding:  0 20px;
    /* background-color: #1C274C; */
    color: white;
}

/* Main section */
.legal-services-container {
    display: flex;
    flex-direction: column;
    gap:20px;
    /* padding-right: 20px; */
    width:75%;

}
.mylisting-add1{
    width:23%;
}

.legal-services-container h1 {
    color: #FFF;
text-align: left;
/* font-family: Lato; */
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 1px;
}

.legal-services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Ensures 3 items per row */
    gap: 20px;
    cursor: pointer;
}

/* Card styles */
.legal-services-card {
    background-color: #1c4966;
    border-radius: 10px;
    width: 328px;
    /* padding: 15px; */
    transition: transform 0.3s ease-in-out;
}
.legal-services-card2{
    background-color: #1c4966;
    border-radius: 10px;
    width: 250px;
    /* padding: 15px; */
    transition: transform 0.3s ease-in-out;
}


.legal-services-image {
    width: 328px;
    height: 243px;
    border-radius: 10px 10px 0 0;
    /* margin-bottom: 10px; */
}
.legal-services-image2{
    width: 250px;
    height: 243px;
    border-radius: 10px 10px 0 0;
    /* margin-bottom: 10px; */
}
.legal-services-card h3 {
    margin: 10px 0 5px;
    font-size: 18px;
}

.legal-services-card p {
    margin: 5px 0;
    color: #ccc;
}

/* Side ad section */
.legal-services-sidebar {
    width: 30%;
    height: fit-content;
    /* border: 1px solid red; */
 
}
.legal-services-sidebar img{
    width: 100%;
    height: 550px;
    object-fit: cover;
}

.legal-services-sidebar h1 {
    font-size: 60px;
    color: white;
}

.legal-services-get-started {
    margin-top: 50px;
    font-size: 30px;
    color: white;
    font-weight: bold;
}

.legal-services-rup {
    font-size: 90px;
    color: white;
    font-weight: bold;
}

.legal-services-sidebar p {
    font-size: 16px;
    color: white;
    text-align: end;
    margin-top: 0;
}

.legal-services-sidebar {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.legal-services-buy-now {
    background-color: green;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    padding: 16px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.legal-services-buy-now:hover {
    background-color: #0056b3;
}
.nodata {
    /* width: 30%; */
    display: flex;
    flex-direction:column;
    gap:20px;
    justify-content: center;
    align-items: center;
    height: 500px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 15px;
    /* padding-top:150px; */
}
.subcategory{
    font-size: 16px;
    font-weight: bold;
    color: #00DBBF;
    transition: color 0.3s ease;
}
.form-select{
    /* font-size: 13px;
    font-weight: bold; */
    background-color: #003B4C;
    border:none;
    padding:10px 8px ;
    color:#ffffffcc;
    outline:none;
}

.nodata p {
    border-radius: 15px;
    border: 3px solid #00DBBF;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    color: #00DBBF;

}

.nodata p:hover {
    background-color: #f0f8ff;
    transition: 0.3s ease;
}


/* Responsive Design */
@media (max-width: 1024px) {
    .legal-services-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    }
}

@media (max-width: 768px) {
    .legal-services-layout-container {
        flex-direction: column; /* Stack main section and sidebar */
    }

    .legal-services-container {
        padding-right: 0; /* Remove right padding */
    }

    .legal-services-grid {
        grid-template-columns: 1fr; /* 1 item per row */
    }

    .legal-services-sidebar {
        margin-top: 20px; /* Add margin between sections */
    }

    .legal-services-sidebar h1 {
        font-size: 40px; /* Adjust font size for smaller screens */
    }
    
    .legal-services-get-started {
        font-size: 24px; /* Adjust font size for smaller screens */
    }
    
    .legal-services-rup {
        font-size: 60px; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .legal-services-sidebar h1 {
        font-size: 32px; /* Further reduce font size */
    }

    .legal-services-get-started {
        font-size: 20px; /* Further reduce font size */
    }

    .legal-services-rup {
        font-size: 48px; /* Further reduce font size */
    }

    .legal-services-buy-now {
        font-size: 16px; /* Adjust button font size */
    }
}
