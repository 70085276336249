.villalist-container {
    padding: 0px 15px 0px 25px;
    /* margin-left: 35px; */
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.proeprtyDummy{
    border: 1px solid red;
}
.villa-card {
    width:95%;
    cursor: pointer;
    margin-right: 10px;
}
.villalist-content {
    width: 100%;
    /* padding:10px 50px 0px 80px; */
    /* justify-content: space-between; */
    /* border: 1px solid red; */
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    gap: 27px;
    /* border: 1px solid red; */
}
.villalist-content-items{
    width: 100%;
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns:repeat(6,15.5%);
    gap: 20px;
  
}
.villalist-dropdowns {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* border: 1px solid red; */
}
.villa-dropdowns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    padding: 10px;
    border-radius: 62px;
    border: 2px 0px 0px 0px;
    background: #002D3A80;
    border: 2px solid #FFFFFF26;
    backdrop-filter: blur(10px);
    /* border: 2px solid red; */
}
.v-addmore {
    width: max-content;
    padding: 10px;
    gap: 10px;
    display: flex;
    color: #00DBBF;
    flex-direction: row;
    border-radius: 45px;
    background: #003B4C;
    justify-content: flex-end;
}
.v-addmore-text {
    background: none;
    border: none;
    color: #00DBBF;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    /* text-align: left; */
}
.v-addmore-menu {
    position: absolute;
    top: 100%;
    left: 14%;
    background: #003B4C;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    min-width: auto;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    text-decoration: none;
}
.v-menu-items {
    position: absolute;
    /* border: 1px solid red; */
    margin: 20px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: auto !important;
    z-index: 99999999;
}
.selected-menu {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    padding: 4px 8px 4px 8px;
    gap: 6px;
    border-radius: 31px;
    ;
}
.selected-menu-item {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-align: left;
}
.v-property-dropdown {
    max-width: auto !important;
    padding: 10px 15px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 45px;
    background: #003C4C;
    justify-content: flex-end;
    /* border: 1px solid red; */
}
.v-property-list {
    border: 2px solid #00DBBF;
    background: #003B4C;
}
@media only screen and (max-width: 320px) {
    .villa-card {
        width: 90%;
    }
    .villa-dropdowns {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
    .villalist-content {
        justify-content: center;
    }
}
@media only screen and (max-width:375px) {
    .villa-card {
        width: 90%;
    }
    .villa-dropdowns {
        /* width: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
    .villalist-content {
        justify-content: center;
    }
}

@media only screen and (max-width: 320px) {
    .villalist-content{
        width: 100% !important;
        display: flex;
        flex-direction: column;
        gap:10px
    }
    .villalist-content-items{
        width: 100%;
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns:repeat(1,98%);
        gap: 20px;
      
    }

}

@media only screen and (min-width:321px) and (max-width:768px){
    .villa-card{
        width: 100% !important;
    
    }
    .villalist-content{
        width: 100% !important;
        display: flex;
        flex-direction: column;
        gap:10px
    }
    .villalist-content-items{
        width: 100%;
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns: repeat(2,48%);
        gap: 20px;
      
    }
}

@media  (min-width:769px) and (max-width:1024px) {
    .villa-card{
        width: 100% !important;
    
    }
    .villalist-content{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap:10px
    }
    .villalist-content-items{
        width: 100%;
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns: repeat(3,31.5%);
        gap: 20px;
      
    }
}

@media  (min-width:1025px) and (max-width:1280px) {
        .villa-card{
        width: 100% !important;
    
    }
    .villalist-content{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap:10px
    }
    .villalist-content-items{
        width: 100%;
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns: repeat(4,23.5%);
        gap: 20px;
      
    }
}


@media (min-width:1280px) and (max-width:1440px) {
    .villa-card{
        width: 100% !important;
    
    }
    .villalist-content{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap:10px
    }
    .villalist-content-items{
        width: 100%;
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns: repeat(5,18.5%);
        gap: 20px;
      
    }

}

@media (min-width:1441px) and (max-width:2560) {
    .villa-card{
        width: 100% !important;
    
    }
    .villalist-content{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap:10px
    }
    .villalist-content-items{
        width: 100%;
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns: repeat(6,15%);
        gap: 20px;
      
    }
}


