
.ep-profile-card {
  background-color: rgba(0, 72, 93, 0.6);
  border: 1px groove rgba(255, 255, 255, 0.1);
  padding: 15px; /* Reduced padding to allow more space */
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 0px;
  /* width: 30%; Increased max-width to fit the image */
  height: 100%;
  overflow: hidden;
}
.ep-profile-image-wrapper{
  width:100%;
}
.ep-profile-image {
  /* width: 88%;  */
  width:340px;
  height:300px;
  /* height: 350px; */
  /* max-width: 0px;  */
  /* height:476px;  */
  border-radius: 16px;
  margin-bottom: 9px;
  margin-top: 4px;
  padding: 10px;
}


  .ep-h3, .ep-h2, .ep-p {
  margin: 10px 0; }  

.ep-mem {
  margin-left: 6px;
}

.ep-group {
  background-color: #00485D;
  width: calc(100% + 40px);
  margin-left: -20px;
  padding: 10px;
  margin-top: -20px;
}

.edit-button{
  background-color: #00DBBF;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.ep-start-listing-button {
  background-color: #00DBBF;
  color: black;
  font-size: 14px;
  font-weight: 400;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
}

.ep-p1:hover, .ep-start-listing-button:hover {
  background-color: #009e8c;
}

.ep-calimg, .ep-editimg {
  width: 15px;
  height: 15px;
}

.ep-group1 {
  display: flex;
  flex-direction: row;
  gap:6px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.ep-h3 {
  font-size: 18px;
  line-height: 24.59px;
  font-weight: 700;
  margin: 1px 0;
}

.ep-mem {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  padding:0;
  margin:0;
}

.ep-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-size: 15px;
  background-color: #00DBBF;
  border-radius: 12px;
  border: none;
  color: #00DBBF;
  width: 100%;
  padding: 10px;
  /* height: 30px; */
  border: none;
  color: #00DBBF;
  width: 100%;
  padding: 10px;
  /* height: 30px; */
  
}

.ep-upload-icon{
  width: 20px;
  height: 20px;
}

.ep-upload-icon{
  width: 20px;
  height: 20px;
}

.ep-upload-icon{
  width: 20px;
  height: 20px;
}

.ep-p1 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  color: black;
  line-height: 19.12px;
  cursor: pointer;
}

.ep-empty-image {
  width: 100%; 
  max-width: 300px; 
  height: auto; 
  margin-bottom: 20px;
}

.ep-h2 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 32.78px;
}

.ep-footer-category {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 200px;
}

.ep-h4 {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.86px;
}

.ep-footer-link {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 250;
  margin-bottom: 5px;
  padding: 2px;
}

.ep-footer-link:hover {
  text-decoration: underline;
}

.ep-copyright {
  background-color: #00495c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px; 
  height: auto; 
  font-size: 12px; 
  
}
.ep-h5{
  margin: 0;
}

.ep-follow {
  display: flex;
  align-items: center;
}

.ep-follow img {
  width: 28px;
  height: 28px;
  padding: 4px;
}

.ep-follow1 {
  margin-left: 10px;
}

.ep-p {
  color: white;
  font-size: 14px;
  font-weight: 250;
  margin: 0;
  
}

.ep-h5 {
  color: #00DBBF;
  font-weight: 500;
  font-size: 16px;

}

.ep-flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 150px;
}

.ep-empty-state {
  background-color: #002D3A;

  padding-top: 90px;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%; 
  margin-left: auto;
  margin-right: auto;
  transform: translateX(10%); 

}

.ep-container {
  display: flex;
  flex-direction: column;
  background-color: #002D3A;
  /* min-height: 100vh; */
  padding: 0;
  width: 100%;
}

.ep-profile-card {
  background-color: rgba(0, 72, 93, 0.6);
  border: 1px groove rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
  overflow: hidden;

}

.ep-footer-links {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  background: #003746;
  color: #ffffff;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  margin-top: 70px;
}

.ep-name-input{
  background-color: transparent;
  border: 1px solid #00DBBF;
  padding: 5px;
  width: 100%;
  color: #00DBBF;
  border-radius: 12px;

}

.ep-name-input::placeholder{
  padding: 2px;
}

.ep-upload-overlay{
  margin-bottom: 10px;
  padding: 7px;
  background-color: transparent;
  color: #00DBBF;
  border: 1px solid #00DBBF;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
}



.ep-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00DBBF;
  border: 1px solid #00DBBF;
  border-radius: 12px;
  color: black;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ep-upload-label:hover {
  background-color: #009e8c;
}

.ep-file-input {
  display: none; /* Hides the actual file input */
}

.ep-upload-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
/* ------------------------------------------ */
@media (max-width:768px){
  .ep-profile-image{
    width: 210px;
    height: 210px;
  }
  
}
@media (max-width:426px){
  .ep-flex-wrapper{
    display: flex;
    flex-direction: column;
    padding:0;
    width:100%;
  }
  .ep-profile-image{
    width: 250px;
    height: 250px;
  }
}
@media (max-width:376px){
  
}
