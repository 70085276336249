.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 100px 0px 100px;
    /* text-align: center; */
    
}
.left-term{
    float: right;
}
h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}
.term-title1{
    font-size: 15px;
    color:white ;
    margin-bottom: 10px;
    font-weight: bold;
    text-decoration:underline;
}
.term-title {
    font-size: 15px;
    color:white;
    margin-bottom: 10px;
    font-weight: bold;
}

.term-content {
    color: rgba(0, 219, 191, 1);
    line-height: 1.6;
    font-weight: 500;
    font-size: 14px;

}

.footer {
    text-align: center;
    color: #999;
    margin-top: 40px;
    font-size: 0.9rem;
}
.refund-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
  }
  
  .policy-section h2 {
    margin-top: 20px;
    color: #333;
  }
  
  .policy-section ul {
    padding-left: 20px;
  }
  
  .policy-section ul li {
    margin-bottom: 10px;
  }
  