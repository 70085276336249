/* Container */
.chat-container {
    display: flex;
    flex-direction: column;
    background-color: #0d3a4c;
    border-radius: 10px;
    padding: 20px;
    color: white;
    width: 100%;
    max-width: 600px;
    margin: auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
/* Header */
.chat-header {
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
}
.close-chat {
    background: none;
    border: 1px solid white;
    border-radius: 99px;
    color: white;
    font-size: 24px; /* Larger font for close button */
    cursor: pointer;
  }

/* Chat Body */
.chat-body {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 20px;
}

.message {
    margin-bottom: 15px;
}

.message-info {
    font-size: 14px;
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}

.message-text {
    background-color: #1e576f;
    padding: 10px;
    border-radius: 8px;
    max-width: 80%;
    color: #f0f0f0;
}

/* Typing Indicator */
.typing-indicator {
    color: #888;
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}

/* Chat Footer */
.chat-footer {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #134759;
    border-radius: 8px;
}

.chat-footer input {
    flex: 1;
    background-color: #1b5366;
    border: none;
    padding: 10px;
    color: white;
    font-size: 14px;
    border-radius: 8px;
}

.chat-footer input::placeholder {
    color: #aaa;
}

/* Chat Actions (Buttons) */
.chat-actions {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.chat-actions button {
    background-color: transparent;
    border: none;
    color: white;
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
}

.chat-actions button:hover {
    color: #00a651;
}

.send-button {
    background-color: #00a651;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    transition: background-color 0.3s ease;
}

.send-button:hover {
    background-color: #008540;
}

.avatar-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    border: solid red;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .chat-container {
        padding: 15px;
    }

    .chat-header {
        font-size: 16px;
    }

    .message-text {
        max-width: 100%;
    }

    .chat-footer {
        flex-direction: column;
    }

    .chat-footer input {
        margin-bottom: 10px;
    }

    .chat-actions {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .chat-header {
        font-size: 14px;
    }

    .message-info {
        font-size: 12px;
    }

    .chat-footer input {
        font-size: 12px;
    }

    .chat-actions button {
        font-size: 16px;
    }

    .send-button {
        padding: 8px;
    }
}
