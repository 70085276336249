/* Mylisting2.css */

.ml2{
  display: flex;
  /* background-color: #0f1a2b; */
  padding: 15px 20px;
  gap: 10px;
}

.ml2-listing-page {
    color: #ffffff;
    /* background: #003D4EE5; */
    padding: 20px;
    border-radius: 12px;
    max-width: 70%;
  }
  
  .ml2-listing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .ml2-back-button {
    background: transparent;
    color: #ffffff;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .ml2-filter-select {
    background-color: #003d4e;
    color: #00dbbf;
    border: none;
    padding: 10px;
    border-radius: 8px;
  }
  
  .ml2-image-section {
    position: relative;
  }
  
  .ml2-house-image {
    width: 100%;
    /* height: auto; */
    max-width: 1070px;
    max-height: 644px;
    border-radius: 30px;
    object-fit: cover;
    /* border-radius: 8px; */
    z-index: 1; /* Place below the overlay */
  }
  
  .ml2-image-overlay {
    position: relative;
    bottom: 0;
    left: 5%; /* Centering */
    transform: translateY(-50%);
    background: rgba(0, 61, 78, 0.9); /* Transparent background to ensure text is visible */
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    height: auto; /* Set auto height */
    z-index: 2; /* Ensure it's below the description but above the image */
    transition: 0.3s ease-in-out;
  }
  
  .ml2-listing-icons {
    display: flex;
    justify-content: space-evenly;
    gap: 62.75px;
  }
  
  .ml2-icon-container {
    align-items: center;
    padding: 5px;
  }
  
  .ml2-icon {
    margin-right: 8px;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color:#00DBBF; /* Change this to your desired color */
    border-radius: 99%; /* Optional: Makes the icon circular */
    padding: 20px;;
    width: 30px; /* Optional: Adjust size */
    height: 30px;
  }
  
  .carousel-control-prev-icon::after,
  .carousel-control-next-icon::after {
    content: ""; /* Ensure no default icon displays */
  }
  
  .ml2-proptype{
    font-size: 13.51px;
    font-weight: 400;
    line-height: 18.46px;
    letter-spacing: 0.9653430581092834px;
    /* text-align: center; */
    color: #00DBBF;
    /* border: 1px solid red; */
    margin: 0px;
    padding: 0px;

  }

  .custom-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #000; /* Change icon color */
    font-size: 1.5rem; /* Adjust size */
  }
  .carousel-control-prev .custom-icon {
    left: 10px; /* Adjust spacing */
  }
  .carousel-control-next .custom-icon {
    right: 10px; /* Adjust spacing */
  }
  
  
  .ml2-details-section {
    margin-top: 34px;
    padding:0px 0px 0px 60px;
  }

  .mldes{
    position: relative;
    z-index: 3; /* Ensure description stays above overlay */
    margin-top: 20px;
    padding: 20px; 
  }
  
  .ml2-description-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color:#FFFFFF80;
    flex-wrap: wrap;
  }

  .ml2-all-moredetails h2{
    margin-top: 40px;
    margin-bottom: 24px;
  }
  
  .ml2-details-grid {
    /* display: grid; */
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 40px;
  }

  .mldetails{
    display: flex;
    flex-direction: row;
    color:#FFFFFF80;
    gap: 180px;
  }

  .mldetque{
    width: 343px;
  }

  .mldetans{
    width: 400px;
  }
  
  .ml2-amenities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .ml2-all-amenities h2{
    margin-top: 40px;
    margin-bottom: 24px;
  }

.ml2-aminityicon{
    max-width: 20px;
    height: 20px;
    gap: 0px;
    border-radius: 2.5px 0px 0px 0px;
    ;
    /* margin-top: 18px; */

}

.ml-amenity-details{
  display: flex;
  flex-direction: row;
  gap:10px;
  align-items: center;
}

  .ml2-aminityinfo{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .ml2-aminityinfo p{
    align-items: center;
    margin-top: 13px;
  }

 
  .ad-container {
    background: #003D4EE5;
    padding: 16px;
    /* border-radius: 12px; */
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-family: 'Arial', sans-serif;
    max-width: 328px;
    margin: 0 auto;
    height:max-content;
    max-height: 800px;
    border-radius: 30px;
    border: 1px solid var(--Stroke, #005771);
    /* background: var(--third-color); */
    /* margin-top: 15px; */
  }
  
  .ad-id {
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
    color: #b2e5e7;
  }
  .ad-feature {
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    /* width: 100%; */
    max-width: Fill (400px)px;
    max-height: Hug (123px)px;
    padding: 0px;
    gap: 10px;
    ;
  }

  .ad-feature img{
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .ad-image {
    margin-bottom: 16px;
  }
  
  .ad-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .ad-price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .ad-details {
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .ml3locarea {
    /* display: flex; */
    /* justify-content: space-between; */
    /* gap: 12px; */
    margin-left: 5px;
  }
  
  .ml3loc {
    display: flex;
    align-items: center;
  }
  
  .ml3loc img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  
  .expiry {
    /* color: #b2e5e7; */
    margin-top: 8px;
    /* font-size: 12px; */
    max-width: Fill (284px)px;
    height: Hug (19px)px;
    gap: 8px;
    ;
    /* font-family: Manrope; */
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: center;
  }
  
  .ad-buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  
  .edit-btn,
  .remove-btn {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #27a6b9;
    color: white;
  }
  
  .remove-btn {
    background-color: #004d66;
    color: white;
  }
  
  
/* Responsive styles for medium screens (tablets, etc.) */
@media (max-width: 1024px) {
  .ml2 {
    padding: 10px 15px; /* Adjust padding for smaller screens */
  }

  .ml2-image-section {
    width: 100%; /* Make image section fit the screen width */
    height: fit-content;
  }

  .ml2-listing-icons {
    gap: 30px; /* Reduce the gap between icons */
  }

  .ml2-icon-container {
    text-align: left; /* Align text to the left on medium screens */
  }

  .ml2-details-section {
    margin-top: 30px; /* Reduce top margin */
    padding-top: 20px;
    z-index: 3;
  }

  .mldetails {
    gap: 100px; /* Adjust gap between details for smaller width */
  }

  .ml2-amenities-grid {
    grid-template-columns: repeat(2, 1fr); /* Two-column layout for amenities */
    gap: 15px; /* Increase spacing between grid items */
  }

  .ad-container {
    max-width: 90%; /* Shrink ad container for better fit */
  }

  .ml3loc img {
    width: 16px; /* Slightly smaller icons */
    height: 16px;
  }
  .ml2-proptype{
    display: none;
  }
  .ml2-propprop{
    font-size: 12px;
  }
}

/* Responsive styles for small screens (mobile) */
@media (max-width: 768px) {
  .ml2 {
    /* border:1px solid red; */
    flex-direction: row; /* Stack elements vertically */
    padding: 16px; /* Reduce padding */
  }

  .ml2-listing-page {
    width:75%   ;
    /* border:1px solid red; */
    padding: 15px;
  }

  .ml2-house-image {
    max-height: 400px; /* Constrain the height for smaller screens */
}

.ml2-details-section {
  margin-top: 20px;
}

/* .ml2-image-overlay {
  display: flex;
  flex-direction: row; 
  flex-wrap: wrap; 
  margin-top: 0;
  padding: 16px;
  width: 100%; 
  left: 1%;
  bottom: 20px;
  transform: translateY(-1%);
  justify-content: space-between; 
} */

.ml2-image-overlay{
  transform: translateY(-35%);

}
.ml2-icon-container p{
  margin:0;
}

.ml2-listing-icons {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 3 equal columns */
  gap: 0px; /* Space between grid items */
  padding: 16px;
}

  /* .ml2-listing-icons {
    flex-direction: row;
    gap: 10px; 
    border:1px solid red;
    padding:0;
  }  */
.ml2-icon-container{
  width:100%;
  height:max-content;

}
  .mldetails {
    gap: 16px; /* Reduce gap between question-answer pairs */
  }

  .mldes {
    padding: 15px;
    margin-bottom: 20px;
}
.ml-amenity-details{
  width:50%;
  /* border:1px solid red; */
}
  .ml2-amenities-grid {
    display: grid;
    /* gap:1px; */
    grid-template-columns: repeat(2, 1fr); /* 3 equal columns */
    /* grid-template-columns: 2fr 2fr; Two columns for amenities */
    /* gap: -8px; Adjust gap */
  }

  .ad-container {
    max-width: 100%;
    padding: 10px;
  }

  .ml3locarea {
    flex-direction: column; /* Stack location and area vertically */
    gap: 10px;
  }

  .ml3loc img {
    width: 14px;
    height: 14px; /* Smaller icons for small screens */
  }

  .ad-buttons {
    gap: 10px; /* Space out buttons */
  }
}

/* Responsive styles for extra small screens (very small mobile devices) */
@media (max-width: 425.6px) {
  .ml2 {
    display: flex;
    flex-direction: column;
    padding: 8px;

  }
  .ml2-house-image {
    width: 100%;
  }

  .ml2-listing-page {
    min-width:400px;
    /* border:1px solid red; */
        padding: 10px; /* Reduce padding further */
  }

  .ml2-image-overlay {
    padding: 15px; /* Adjust padding */
    bottom: 0;
    width: 99%; 
    left: 1%;
    transform: translateY(-10%);
    padding-left:0;
  }

  .ml2-listing-icons {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    flex-direction: column; /* Stack icons vertically */
    /* margin-left: 0;; */
    align-items: flex-start;
    padding-left:0;
    /* gap: 5px; */
  }

  .ml2-icon-container {
    text-align: left; /* Align icons and text to the left */
  }

  .ml2-description-text {
    font-size: 14px; /* Smaller font for description */
  }

  .ml2-house-image {
    max-height: 300px;
}

  .ml2-amenities-grid {
    grid-template-columns: repeat(2,1fr); /* Single-column layout for amenities */
    gap: 5px; /* Reduce gap between items */
  }

  .ad-container {
    padding: 8px;
    /* border:1px solid red; */
    width:100%; /* Full width */
  }

  .ad-price {
    font-size: 16px;
  }

  .edit-btn,
  .remove-btn {
    font-size: 10px; /* Smaller buttons */
    padding: 6px;
  }

  .mldetails {
    flex-direction: row; /* Stack details vertically */
    gap: 10px; /* Reduce gap for very small screens */
  }

  .mldes {
    padding: 10px;
    margin-bottom: 10px;
}

  .ml2-details-section {
    margin-top: 15px;
}

  .mldetque,
  .mldetans {
    width: 100%; /* Make questions and answers full width */
  }
}