.home-header {
    color: white;
    background-image: -webkit-linear-gradient();
}

.my-background-image {
    padding: 30px;
}
.footer-text{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.plantype{
    position: absolute;
    top:10px;
    left: 18px;
    background: rgba(0, 45, 58, 0.5);
    backdrop-filter: blur(10px);
    color: rgba(0, 219, 191, 1);
    font-size: 16px;
    padding: 5px;
    font-weight: 500px;
    border-radius: 99px;

}
.card-fav-icon{
    /* border: 1px solid red; */
    position: absolute;
    z-index: 1;
    /* margin-left: 175px; */
    /* margin-top: -200px; */
        right:10px; 
     top:10px;
}
.list-unstyled {
    padding-left: -57px !important;
    list-style: none;
  }
.custom-arrow-icon{
    /* border: 1px solid red; */
  position: absolute;
right:10%;
top:35%;
 
}
.slick-dots li button:before {
    color:#00DBBF !important;
}
.downarrow-icon{
    /* border: 1px solid red; */
    position: absolute;
    right:10%;
    bottom:35%;
}
.property-card {
    width:92%;
    min-height: 100px;
    margin: 0 auto;
    padding: 10px 8px 16px 8px;
    border-radius: 16px;
    border: 1px 0px 0px 0px;
    background: #003D4E;
    border: 1px solid #005771;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
border: 1px solid var(--Stroke, #005771);
background: var(--Card, #003D4E);
}
.property-image{
width:-webkit-fill-available;
    object-fit: cover;
    /* margin-left: 2px; */
    align-items: center;
    border-radius: 8px;
}
.property-image img {
   width: 100%; 
    height: 30vh;
    max-height: auto;
}
.property-card-content { max-width:100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

}

.property-card-content p {
    padding: 0;
    margin: 0;
}

.property-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: center;
    display: -webkit-box;             /* Required for webkit browsers */
  -webkit-box-orient: vertical;     /* Required for webkit browsers */
  overflow: hidden;                 /* Hides overflow content */
  text-overflow: ellipsis;          /* Adds "..." at the end of the overflowed text */
  -webkit-line-clamp: 1;            /* Number of lines to show */
  line-height: 1.5;                 /* Adjust line height */
  max-height: calc(1.5em * 3);  
}

.property-price {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: center;

}

.NewProperty-container {
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
    /* padding: 50px 0px 0px 0px; */
    /* border: 1px solid red; */
}

.newProperty-title {
    padding-bottom: 16px 40px;
    font-size: 24px;
    font-weight: 800;
    line-height: 28.8px;
    letter-spacing: 1px;
    text-align: center;

}

.newlyListed {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    letter-spacing: 1px;
    text-align: center;
    color: #00DBBF;
}
.component-title{
    padding:16px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;

}
.component-title p{
    margin: 0;
}

.newproperty-content {
  display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 20px;
    /* border: 1px solid red; */
    padding:30px;

    /* ////
     */
     
}

.fh_image {
    /* border: 1px solid red; */
    width: 100%;
}
.ads-image{
    /* border:1px solid red; */
}
.fh_image img {
    width: 100%;
    padding: 20px 40px;
    height: 360px;

   
}
.newvilla-content{
        /* display: flex; */
          /* justify-content: center; */
          align-items: center;
          display: flex;
          gap: 20px;
   width: 100%;
          padding:20px 50px 20px 50px;
          /* border: 1px solid red; */
      
          /* ////
           */
           
      
}
.footer-content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.servicecategory{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.categoryservice-container{
    width: 100%;
    display:grid;
    grid-template-columns: repeat(4,20.9%);
    gap:70px;
    padding: 0px 30px 10px 30px;
    /* border: 1px solid red; */
}
.category-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:16px;
    padding: 0px 30px 10px 30px;
}
.slider-container {
    width: 100%;
color: white;
/* flex-direction:row; */
/* gap:20px; */
padding: 0px 30px 10px 30px;
  }
/*  */
.slider-container button{
    size: 10%;
}
.slider-container li:focus{
width: 20px;
}
.comfort-living-container{
  /* border: 2px solid red; */
  display: grid;
  grid-template-columns: repeat(6,16.3%);
  flex-direction: row;
  gap:20px 8px;
padding-right: 10px;
margin-left:5px;
}
.villa-card {
  /* max-width: 95%; */
  width: 100%;
    margin: 0 auto;
    /* min-height: 370px; */
    padding: 6px;
    border-radius: 16px;
    border: 1px 0px 0px 0px;
    background: #003D4E;
    border: 1px solid #005771;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}
.slick-track{
    /* border: 1px solid red; */
    /* display: flex; */
    /* position: none; */
    /* justify-content: center; */
    display:flex !important;

}
.text-bg-info{
    background-color: #00DBBF !important;
}

.villa-image {
    width: 100%;
    height: 30vh;
    /* height: 197px; */
    object-fit: cover;
    margin-left: 3px;
    align-items: center;
    border-radius: 8px;
    position: relative;
}
.service-image{
    position: relative;
}

.villa-image img {
    width:100%;
    /* max-width: 211.5px; */
    height: 30vh;
    object-fit: cover;
    border-radius: 8px;
}

.villa-featured {
    position: absolute;
    top: 225%;
    left: 20px;
    padding: 6px 12px 6px 12px;
    border-radius: 16px;
    background: #00030480;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: left;
    color: #00DBBF;
}

.villa-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.villa-card-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    justify-content: space-between;
    text-align: left;
    /* color: #00DBBF; */

}

.villa-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.villa-price {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #00485D;
    padding: 6px;
    color: #00DBBF;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: left;
    cursor: pointer;

    width: 100%;
}

.villa-location {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: left;
    color: #FFFFFFB2;

}
.villa-area{
    display: flex;
    flex-direction: row;
    gap:10px;
    height: 20px;
}
.footer-container {
    width: 100%;
   margin-top:50px;
   height: max-content;
    /* border: 2px solid red; */
    /* position: fixed; */
    bottom: 0;
    left: 0;
    color: #fff;
    /* padding: 20px; */
    text-align: center;
    /* margin-top: 90px; */
    background: #003746;

}

.footer-content {
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    text-align: center;
    justify-content: center;
    padding:  40px ;
}

.text-uppercase {
    /* text-transform: uppercase; */
    /* border: 1px solid red; */
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: center;
    display: -webkit-box;             /* Required for webkit browsers */
  -webkit-box-orient: vertical;     /* Required for webkit browsers */
  overflow: hidden;                 /* Hides overflow content */
  text-overflow: ellipsis;          /* Adds "..." at the end of the overflowed text */
  -webkit-line-clamp: 1;           
  /* line-height: 2;                 Adjust line height */
  /* max-height: calc(1.5em * 6);   */

}

.footer-row {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 80%;
    /* border: 1px solid red; */
    /* min-height: 40vh; */


}

.col {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    /* border: 1px solid red; */

}

.list-unstyled {
    display: flex;
    flex-direction: column;
gap:15px;
    text-align:center;
    /* border: 1px solid red; */
    width: 100%;
    height:fit-content;
}

.footer-list {
    display: flex;
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    /* letter-spacing: 1px; */
    text-align:left;
    /* margin-left: 35%; */
    cursor: pointer;
    /* overflow: hidden; */
    /* border: 1px solid red; */

}



.footer-list-text:hover {
    text-decoration: underline;
   height: 40px !important;
}
.footer-list-text{
    color: #00DBBF;
}

.footer2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    justify-content: space-between;
    /* border: 1px solid red; */
    /* margin-top: 10%; */
    padding: 0px;
    background: #00495C;

    /* text-align: center; */
    /* border: 1px solid red; */
}
.footer2 p{
    margin-top: 20px;
    /* border:1px solid red; */
}
.footer-left{
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 30px;
    /* border: 1px solid red; */
 justify-content: center;
    align-items: center;
}
.footer-follow{
/* margin-right: 45%; */
}
.footer-googlestore {
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    width: 25%;
    height: 20%;
    gap: 20px;
    justify-content: center;
    /* border: 1px solid red; */
  }
  
  .footer-googlestore a {
    display: flex; /* Ensures the link behaves like a block element */
    width: 40%; /* Matches the image width */
  }
  
  .googlestore {
    width: 100%; /* Ensures the image fills the link's container */
    /* border: 1px solid red; */
  }
  
.followus {
    display: flex;
    flex-direction:row;
    gap: 20px;
    align-items: center;
    /* border: 1px solid red; */
    text-align: center;
    justify-content:flex-end;
    
}
.follow{
height: 7px;
font-size: medium;
font-weight: 400;
}

.follow-icons {
    display: flex;
    align-items: center;
    /* border: 1px solid red; */

    gap: 10px;
}

.follow-icons img{
    width:28px;
    height:28px;
}


.categories-container {
    width: 100%;
    /* padding: 20px; */
}

.newProperty-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.categories-content {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    scroll-behavior: smooth;
    cursor: grab; /* Changes cursor to indicate draggable content */
    -ms-overflow-style: none;  /* Hides scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hides scrollbar for Firefox */
}
/* Increase arrow size */
.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
    font-size: 2rem; /* Increase the font size for larger arrows */
    width: 50px; /* Increase width */
    height: 50px; 
    line-height: 50px; 
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Add background */
    color: white; /* Arrow color */
    border-radius: 50%; /* Optional: Make arrows round */
    border: 2px solid white; /* Optional: Add a border */
    transition: all 0.3s ease;
    /* font-size: larger; */
}
.owl-nav span{
    font-size: 30px;
}

/* Hover effect to make the arrow bigger */
.owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-next:hover {
    background-color: rgba(255, 255, 255, 0.4); /* Change background on hover */
    transform: scale(1.2); /* Slightly enlarge on hover */
}

/* Position the arrows closer to the edges */
.owl-carousel .owl-nav .owl-prev {
    left: -30px; /* Adjust position of previous arrow */
}

.owl-carousel .owl-nav .owl-next {
    right: -30px; /* Adjust position of next arrow */
}

.categories-content:active {
    cursor: grabbing; /* Cursor when dragging */
}
.categories-content::-webkit-scrollbar {
    display: none; /* Hides scrollbar for Chrome, Safari, and Opera */
}
/* Custom navigation buttons */
.carousel-prev,
.carousel-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: 1000; /* Make sure buttons are above the carousel */
}

.carousel-prev {
    left: -30px; /* Position the left arrow outside the carousel */
}

.carousel-next {
    right: -30px; /* Position the right arrow outside the carousel */
}

/* Optional: Change button color on hover */
.carousel-prev:hover,
.carousel-next:hover {
    background: rgba(0, 0, 0, 0.7);
}

.categories-card {
    /* width:50%; */
    height: 20vh;
    /* flex-shrink: 0; */
    padding: 12px;
    border: 1px solid #005771;
    border-radius: 10px;
    position: relative;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    cursor: pointer;
    /* transition: transform 0.3s ease-in-out; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center ;
    width: 329px;
    height: 150px;
    padding: 23px 25px;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid var(--Stroke, #005771);
    background: var(--Card, #003D4E);
}

.categories-image{
/* border: 1px solid red; */
/* border: 1px solid red; */
/* width: 100%; */
/* height: 50vh; */
/* margin-left: 30px; */
display: flex;
align-items: center;
justify-content: center;
gap: 0px;
border-radius: 12.5px;
/* text-align: center; */


}
.categories-image img{
 /* border: 1px solid red; */
 /* width: 50% !important;
 height: 10vh; */
 width: 50px;
height: 50.001px;
}
.categories-card-content {
    width: 100%;
    /* border: 1px solid red; */
    /* margin-left: 30px; */
    text-align: center;
    height:24px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.categories-title {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 1px;
    text-align: center;
    
}
.footer-text-links{
    /* border:1px solid red;     */
    /* display: flex;
    flex-direction: row;
    gap:40px;
    height: max-content; */
}
.footer-columns-wrapper {
    display: flex;          /* Enables flexbox layout for horizontal alignment */
 flex-direction: row;      /* Allows items to wrap to the next line if they exceed the container width */
    /* gap: 40px;  */
    justify-content: space-between;
    /* border: 1px solid red; */
    align-items: center;
    /* height:30vh;   */
    padding:30px 40px 10px 40px;
    /* margin:0 ;          Adds space between columns */
}

.footer-column .col {
    flex: 1 1 200px;        /* Sets a base width for each column and allows them to grow/shrink */
    min-width: 150px;       /* Ensures columns don’t get too narrow on smaller screens */
    max-width: 200px;       /* Limits the maximum width of each column */
}

.footer-column h5 {
    margin-bottom: 10px;    /* Adds space below the header */
}

.footer-list {
    list-style: none;       /* Removes default bullet points */
}

.footer-list a {
    color: rgb(158, 157, 157) !important;
    height:16px;
    overflow: hidden;
    text-decoration: none;         /* Ensures links are visible */
    text-decoration: none;  /* Removes underlines */
}

.footer-list a:hover {
    text-decoration: underline;
     
    /* Adds underline on hover for clarity */
}
.footer-logo{
    /* border: 1px solid red; */
    width: 65%;
    /* height: 10vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 45px;
    justify-content: space-between;
    /* margin-bottom: 8%; */
}
.TandC{
   font-size: 14px;
   color:#00DBBF;
   font-weight: 500;
   cursor: pointer;
}
.footer-desc {
    /* border: 1px solid red; */
    width: 88%;
    font-size: 14px;
    font-weight: 400;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    text-align:justify;
    display: -webkit-box;           /* Enables the multiline ellipsis */
    /* -webkit-line-clamp: 3;          Limits the text to 3 lines */
    -webkit-box-orient: vertical;   /* Sets the box orientation to vertical */
}

.logo-footer{
    /* border: 1px solid red; */
    width: 100%;
    height: 60px;
    padding-right: 46%;
}
.villa-card-container{
        display: grid;
        width:100%;
        grid-template-columns: repeat(6,15.5%); /* Adjust min-width as needed */
        gap: 20px;
        /* border: 1px solid red; */
        padding: 10px 10px 0px 0px;
}
.villa-card-container .villa-card{
    margin:0;
}


@media only screen and (max-width:1024px) {
    .text-uppercase {
        /* text-transform: uppercase; */
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
         .footer-row{
           display: flex;
           flex-direction:row;
           gap: 16px;
       }
       .footer-list {
        font-size: 11px;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 1px;
        text-align: left;
    }
       /*.footer2{
           display: flex;
           flex-direction:row;
       } */
          
           } 
@media only screen and (max-width:769px) {
    .categoryservice-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(2,33%);
        gap:70px;
        padding: 0px 30px 10px 30px;
        /* border: 1px solid red; */
    }
            .text-uppercase {
                /* text-transform: uppercase; */
                font-size: 10px;
                font-weight: 400;
                line-height: 13px;
                text-align: left;
            }
                 .footer-row{
                   display: flex;
                   flex-direction:row;
                   gap: 16px;
               }
               .footer-list {
                font-size: 9px;
                font-weight: 300;
                line-height: 14px;
                height:16px;
                letter-spacing: 1px;
                text-align: left;
                overflow:hidden;
                text-overflow: ellipsis;     
            }
            .footer-content{
                padding:20px;
            }
            .list-unstyled{
                gap:8px;
            }
            
        }
@media only screen and (max-width: 320px) {
    .footer-logo{
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 80px;
        justify-content: space-between;
        /* margin-bottom: 8%; */
    }
    .footer-googlestore{
        display: flex;
        flex-direction: row;
        margin-left: 40px;
        width: 60%;
        height: 20%;
        gap: 20px;
        justify-content: center;
    }
    .slick-dots{
        position: absolute;
        bottom: -62px !important;
        width: 100%;
        text-align: center;
    }
   .property-card{
    width: 100%;
    /* height: 100vh; */
    justify-content: space-between;
   }
   .property-image{
    width: 100%;
    height: 40vh;
    object-fit: cover;
   }
 .property-image{
     width: 98%;
     height:100%;
 }
 .property-image img{
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
 }
 .categoryservice-container{
    width: 100%;
    display:grid;
    grid-template-columns: repeat(2,20.9%);
    gap:70px;
    padding: 0px 30px 10px 30px;
    /* border: 1px solid red; */
}
.villa-card{
    width: 100% !important;

}
 .comfort-living-container{
    /* border: 2px solid red; */
    display: grid;
    grid-template-columns: repeat(1,100%);
    flex-direction: row;
    gap:20px 8px;
  padding-right: 10px;
  margin-left:5px;
  }
 .footer-row {
    display: flex;
    flex-direction:column;
    gap: 80px;
    width: 100%;
    min-height: 40vh;
}
.footer2{
    display: flex;
    flex-direction: column;
}
.villa-image{
 /* border: 1px solid red; */
width: 100%;
height: 40vh;
}

    }
@media only screen and (min-width:321px) and (max-width:768px){
    /* .villa-image img{
        width: 60%;
        height: 30vh;
    } */
    .footer-row {
        display: flex;
        flex-direction:column;
        gap: 80px;
        width: 100%;
        min-height: 40vh;
    }
    .footer2{
        display: flex;
        flex-direction: column;
    }
    .footer-logo{
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 80px;
        justify-content: space-between;
        /* margin-bottom: 8%; */
    }
    .footer-googlestore{
        display: flex;
        flex-direction: row;
        margin-left: 40px;
        width: 60%;
        height: 20%;
        gap: 20px;
        justify-content: center;
    }
    .categories-card{
        width: 100%;
    }
    .categoryservice-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(2,49%);
        gap:10px;
        padding: 0px 30px 10px 30px;
        /* border: 1px solid red; */
    }
    .custom-arrow-icon{
        position: absolute;
        right:11%;
        top:35%
    }
    .comfort-living-container{
        /* border: 2px solid red; */
        display: grid;
        grid-template-columns: repeat(2,50%);
        flex-direction: row;
        gap:20px;
      padding-right: 10px;
      margin-left:5px;
      }
      .villa-card-container{
        display: grid;
        width:100%;
        grid-template-columns: repeat(3,32%); /* Adjust min-width as needed */
        gap: 20px;
        /* border: 1px solid red; */
        padding: 10px 10px 0px 0px;
}
    .downarrow-icon{
        position: absolute;
        right: 11%;
        bottom: 35%;
    }
}


    

@media only screen and (max-width:768px) {

  .footer-row{
     display: flex;
     flex-direction:row;
     flex-wrap: wrap;
 
 }
 .footer2{
     display: flex;
     flex-direction: column;
 }
 /* .custom-arrow-icon{
    position: absolute;
    right:20%;
    top:35%
}
.downarrow-icon{
    position: absolute;
    right: 20%;
    bottom: 35%;
} */
    
     }
     @media screen and (min-width: 1280px) and (max-height: 800px) {
        .property-card{
             width: 95%;
             height: auto;
             margin: 0 auto;
         }
 
         .villa-card{
             max-width: 100%;
             height: auto;
             margin: 0 auto;
         } 
         .property-image{
            /* max-height: 25vh; */
            width: 100%;
            height: 30vh;
            /* border: 1px solid red; */
         }     
     } 



     @media  (min-width:769px) and (max-width:1024px) {
    .villa-image img{
        min-width: 88%;
        max-width:100%;
    }
    .custom-arrow-icon{
        position: absolute;
        right:18%;
        top:35%
    }
    .categories-card{
        width: 100%;
    }
    .categoryservice-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(3,31%);
        gap:20px;
        padding: 0px 30px 10px 30px;
        /* border: 1px solid red; */
    }
    .comfort-living-container{
        /* border: 2px solid red; */
        display: grid;
        grid-template-columns: repeat(3,32%);
        flex-direction: row;
        gap:20px;
      padding-right: 10px;
      margin-left:5px;
      }
      .villa-card-container{
        display: grid;
        width:100%;
        grid-template-columns: repeat(4,23.7%); 
        gap: 20px;
        /* border: 1px solid red; */
        padding: 10px 10px 0px 0px;
}
    .downarrow-icon{
        position: absolute;
        right: 18%;
        bottom: 35%;
    }

}


@media (min-width:1025px) and (max-width:1280px) {
    .villa-image img{
        min-width: 88%;
        max-width:100%;
    }
    .custom-arrow-icon{
        position: absolute;
        right:18%;
        top:35%
    }
    .categories-card{
        width: 100%;
    }
    .categoryservice-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(3,32%);
        gap:20px;
        padding: 0px 30px 10px 30px;
        /* border: 1px solid red; */
    }
    .comfort-living-container{
        /* border: 2px solid red; */
        display: grid;
        grid-template-columns: repeat(4,22.8%);
        flex-direction: row;
        gap:30px;
      padding-right: 10px;
      margin-left:5px;
      }
      .villa-card-container{
        display: grid;
        width:100%;
        grid-template-columns: repeat(6,19%); /* Adjust min-width as needed */
        gap: 20px;
        /* border: 1px solid red; */
        padding: 10px 10px 0px 0px;
}
    .downarrow-icon{
        position: absolute;
        right: 18%;
        bottom: 35%;
    }

}

@media  (min-width:1280px) and (max-width:1440px) {
    .villa-image img{
        min-width: 88%;
        max-width:100%;
    }
    .custom-arrow-icon{
        position: absolute;
        right:18%;
        top:35%
    }
    .downarrow-icon{
        position: absolute;
        right: 18%;
        bottom: 35%;
    }
    .categories-card{
        width: 100%;
    }
    .categoryservice-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(3,32%);
        gap:20px;
        padding: 0px 30px 10px 30px;
        /* border: 1px solid red; */
    }

}

@media  (min-width:1440px) and (max-width:2560px) {
    .categories-card{
        width: 100%;
        height: 200px;
    }
    .categoryservice-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(4,24%);
        gap:20px;
        padding: 0px 30px 10px 30px;
        /* border: 1px solid red; */
    }
}








      