.Toastify__toast-container {
    z-index: 99; /* High enough to stay above other elements */
  }
  
.lp-container {
    padding: 20px;
    /* height:100vh; */
    /* height: auto; */
    font-family: 'Lato', sans-serif;
    background-image: url('../Assets/background.png'); /* Background image here */
    background-size: cover;
    /* background-position: center; */
    
}

.lp-background1{
    display: flex;
    gap: 127px;
    justify-content: space-between;
    padding: 187px 100px 236px 100px;
    align-items: center;
    
}

.lp-left-content{
   /* min-width: 742px; */
    /* max-width: 785px; */
}
.lp-left-content h1 {
    font-size: 50px;
    color: #fff;
    width: 580px;
}

.lp-img-3{
    width: 20px;
    height: 20px;
}

.lp-left-content h1 span {
    color: #00D4B5;
    font-style: italic; /* Highlighted 'Sell' text color */
}

.lp-options {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.lp-options button {
    background-color:#002D3A80;
    color: #fff;
    border: none;
    padding: 16px 24px;
    border-radius: 16px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    gap: 12px;
    align-items: center;
    transition: background-color 0.3s;
}


.lp-options button:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

/* Form section styles */
.lp-form {
    background-color: rgba(0, 45, 58, 0.7);
    border-radius: 30px;
    color: #fff;
    width:100%;
    /* max-width: 328px; */
    /* max-width: 350px; */
    /* height: 100%; */
    border: 1px solid #005771;
    border-radius: 30px;
    border: 1px solid var(--Stroke, #005771);
    background: rgba(0, 61, 78, 0.80);
    backdrop-filter: blur(5px);
}

.lp-form-1 , .lp-form-2{
    padding: 16px 0px; 
}

.lp-form-2{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.lp-divide-line{
    border: 1px solid #005771;
    width: 100%;
}

.lp-form-ready {
    padding: 10px 0px 20px 0px;
    /* margin-bottom: 20px; */
    border-bottom: 1px solid #005771;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
}

.lp-form p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}

.lp-form-options,.lp-form-options-1 {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}
.lp-form-2-1,.lp-form-2-2,.lp-form-2-3{
    display: flex;
    flex-direction: column;
    gap:12px;
}
.lp-form-options button {
    background-color: #003b4c;
    border: none;
    color: white;
    width: 100%;
    /* width: 92px; */
    padding: 12px 16px;
    border-radius: 45px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lp-form-options-1 button {
    background-color: #003b4c;
    border: none;
    color: white;
    width: 100%;
    /* max-width: 92px; */
    padding: 12px 16px;
    border-radius: 45px;
    margin-right: 10px;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.lp-form-options button.active {
    background-color: transparent;
    border: 1px solid #00dbbf;
    color: #00dbbf;
}

.lp-form-options button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.lp-form-2-3{
    width: 100%;
}

.lp-form-input {
    /* width: 90%; */
    padding: 16px;
    /* margin: 10px 0; */
    border-radius: 45px;
    background-color: #00475b;
    color: white;
    border: none;
}

.lp-start-button {
    background-color: #00D4B5;
    color: #00475b;
    width: 100%;
    padding: 12px;
    /* margin-top: 20px; */
    border: none;
    border-radius: 45px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    /* margin-top: 20px; */
}

.lp-start-button:hover {
    background-color: #00475b;
    color: white;
}


.lp-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    padding: 10px;
    z-index: 10;
    background-color: rgba(0, 45, 58, 0.8); 
    border-radius: 16px;
    border: 1px solid #005771;
}

.lp-navigation-1 {
    display: flex;
    align-items: center;
    gap: 24px;
}

.lp-navigation-1 img {
    width: 57px;
    height: 45px;
}

.lp-location {
    display: flex;
    align-items: center;
    color: #00dbbf;
    gap: 8px;
}

.lp-navigation-2 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.lp-navigation-2 button {
    background-color: transparent;
    border: 1px solid #00dbbf;
    color: #00dbbf;
    font-weight: 500;
    border-radius: 12px;
    padding: 8px 24px;
    height: 38px;
    cursor: pointer;
}

.lp-navigation-2 button:hover {
    color: #00D4B5;
}

.lp-img-2 {
    width: 38px;
    cursor: pointer;
}

.lp-img-1{
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.lp-corner {
    display: flex;
    align-items: center;
    background-color: #003b4c;
    gap: 10px;
    border-radius: 42px;
    height: 38px;
    width: 80px;
    color: #00D4B5;
}


/* Form option buttons hover and active states */
.lp-form-options button:hover,
.lp-form-options-1 button:hover {
  background-color: rgba(255, 255, 255, 0.4); /* Hover effect */
}

.lp-form-options button.active,
.lp-form-options-1 button.active {
  background-color: transparent;
  border: 1px solid #00dbbf;
  color: #00dbbf; /* Active button style */
}

/* Specific hover for Start Now button */
.lp-start-button:hover {
  background-color: #00475b;
  color: white;
}

/* Hover effects for navigation buttons */
.lp-navigation-2 button:hover {
  color: #00D4B5;
}

/* Hide spinners in WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ---------------------------------------------------------------- */
@media (max-width:1024px){
    .lp-background1{
        padding:10px;
        gap:10px;
    }
    .lp-left-content h1{
        font-size: 20px;
    }
}
@media (max-width:768px){
    .lp-background1{
        flex-direction:column;
        gap:30px;
      }
    .lp-left-content h1{
        text-align: center;
        width:100%;
        font-size:20px;
    }
    .lp-form{
        width:100%;
      }
}

@media only screen and (max-width: 320px) {
    .lp-left-content{
        margin-top: 130px;
    }

    .lp-form{
        width: 110%;
    }
    .lp-form-options{
        width: 100%;
    }
    .lp-form-options button{
           width: 100px;
           font-size: 14px;
    }
    .lp-form-options-1 button{
        width: 100px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media only screen and (min-width:321px) and (max-width:768px){
    .lp-left-content{
        margin-top: 130px;
    }
    .lp-background1{
        flex-direction:column;
        gap:30px;
      }
}
@media  (min-width:769px) and (max-width:1024px) {
    .lp-left-content{
        margin-top: 130px;
    }
    .lp-left-content h1{
        margin-left: 180px;
        padding-left: 9%;
    }
    .lp-background1{
        flex-direction:column;
        gap:30px;
      }
}

@media (min-width:1025px) and (max-width:1280px) {
    .lp-form{
        /* margin-right: 70%; */
    }
    .lp-background1{
     display: flex;
     flex-direction: row;
     gap:10px
    }
}
@media  (min-width:1440px) and (max-width:2560px) {
    .lp-form{
        max-width: 40%;
        height: auto;
    }
}