.serv-cont{
    display: flex;
    flex-direction: row;
    width:100%;
    height:auto;
    /* gap: 30px; */
    padding:20px 40px;

}
.serv-cont1{
    display: flex;
    flex-direction: row;
    height: 100vh;
    
}
.serv-left-cont{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-right: 20px;
    /* justify-content: space-between; */
    width:100%;
}
.serv-breadcrumb-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
}
.advertisement{
    width:70%;
    margin-left: 25%;
    /* border: 10px solid red; */
    height: 610px;
    border-radius: 20px;
}

.serv-cards{
    display: grid;
    flex-direction: row;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(3,1fr);
    gap:20px;
    /* justify-content: space-around; */
}
.serv-card-full-cont{
    flex:0 0 326px;
    box-sizing: border-box;
}
.serv-card img{
    height: 90px;
    width: 90px;
}
.thir-text{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap:20px;
width: 100%;
align-items: center !important;
justify-content: center !important;
/* margin-top: 13%; */

}

.serv-card{
    
    height:110px;
    display: flex;
    padding:8px 8px 12px 8px;
    flex-direction: row;
    gap:12px;
    /* justify-content: space-between; */
    padding:8px 8px 12px 8px;
    border:1px solid #005771;
    border-radius:12px  ;
    cursor: pointer;
    /* width:100%; */
}
.serv-details{
    text-align: left;
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.serv-details p{
    padding:0;
    margin:0;
    font-weight: 500;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sf-title-right{
    /* border: 1px solid red; */
}
.serviceedit-icon{
    /* border: 1px solid red; */
    display: flex;
    gap:10px
}
.edit-icon{
    width: 30px;
    height: 25px;
}
.job-salary{
    font-size: 12px;

}
.serv-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.serv-stats{
    display: flex;
    flex-direction: row;
    gap:8px;
    justify-content: space-between;
    color:rgb(255, 255, 255 ,0.7);
}
.serv-stats img{
    width:18px;
    height:18px;
}
.serv-stats div{
    width:50%;
    display: flex;
    align-items: center;
    gap:4px;
}
.serv-trail{
    font-size: 12px;
    font-weight:500;
    padding:6px;
    text-align: center;
    color:rgb(255, 255, 255);
    background: var(--Stroke, #005771);
        border-radius:0 0 12px 12px;
    cursor: pointer;
}
.breadcrumb-item a, .sf-breadcrumb-item {
    color: #FFFFFFcc;
    text-decoration: none;
}
.three-dots {
    cursor: pointer;
    font-size: 18px; /* Adjust if needed */
  }
  
  .popup-menu {
    position: absolute;
    top: 10px; /* Positions it above the icon */
    right: 10px; /* Aligns it to the right of the icon */
    background-color: #002D3A;
    border: 1px solid #005771;
    border-radius: 4px;
    padding: 6px 8px ;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    z-index: 1000;
    cursor: pointer;
  }
  
  .popup-menu button {
    background: none;
    border: none;
    /* padding: 6px 0; */
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    width: 100%;
  }  
/* --------------------------------------------------------- */
@media (min-width:1025px){
    .serv-card-full-cont{
        flex:0 0 320px;
    }
}
@media (min-width:786px) and (max-width:1024px) {
.serv-cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
}

@media (min-width:426px) and (max-width:768px){
    .serv-cards{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .serv-card-full-cont{
        width:260px;
    }
}
@media (max-width:426px){
    .serv-right-cont{
        display: none !important;
    }
    .serv-left-cont , .serv-cont{
        width:100%;
    }
    .serv-cards{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    .serv-card-full-cont{
        width: 100%;
        height:100px !important;
    }
    .serv-cont{
        gap:0;
        padding:20px;
    }
}
@media (max-width:375px){
    .serv-cont{
        gap:0;
        padding:20px;
    }
}
@media (max-width:320px){
    .serv-card,.serv-trail{
        width:285px;
    }
}

