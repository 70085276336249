@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Manrope", sans-serif !important;
  font-weight: 200;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #00DBBF;
  --secondary-color: #002d3a;
  --color-light: #002D3A80;
  --footer-color: #00495C;
  --font: 14px;
  --padding: 10px;
}

/* .my-bg-primary {
  background: var(--primary-color);
} */

.my-bg-secondary {
  background: var(--secondary-color);
}

.my-bg-light {
  background: var(--color-light);
}

.my-btn {
  padding: 10px;
  border-radius: 16px;
}

.border_radius {
  border-radius: 16px;
}
.no-of-views{
  display: flex;            /* d-flex */
  justify-content: flex-start; /* justify-start */
  width: 100%;              /* w-100 */
  margin-top: 1rem;  

}
.text_font {
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 200;
  line-height: 19.12px;
  text-align: center;

}
.stars {
  /* border: 1px solid red; */
  width: 70px !important;
  height: 20px !important;
}
.building{
  width: 20px !important;
  height: 20px !important;
margin-bottom: 3px;
}

/* 
.navbar {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
} */

.my-background-image {
  background-image: url('./Assets//villa.svg');
  background-size: cover;
  z-index: 100; /* Ensures images are loaded in the front */
  position: relative;
}

.nav_section_right .Property-btn:hover {
  background: var(--secondary-color);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.chat-dots-btn {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.chat-dots-btn:hover {
  background-color: #002d3a;
  border-color: var(--color-light);
  color: var(--primary-color);
}

.profile_button {
  background-color: var(--secondary-color);
  border: none;
  width: 50px;
  padding: 10px 15px;
  margin: 10px;
  position: relative;
  right: 50px;
  z-index: -5;
}

.profile_button:focus,
.profile_button:active {
  background-color: var(--secondary-color);

}


/* ========== HOME PAGE STYLES */

.hero-section {
  /* background: url('./Assets//mainBackground.svg') no-repeat center center; */
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  
}

.hero-content {
  /* border: 2px solid red; */
  margin-top: 23% ;
}
.hero-title {
  font-size: 56px;
  margin-bottom: 20px;
  width: 50%;
  font-weight: 600;
}

.hero-title .highlight {
  color: #00d9a7;
}

.search-form .form-control {
  margin: 5px 0;
  background-color: #fff;
  color:#002d3a;
}

.search-form input::placeholder{
  background-color: #fff;
  color:#002d3a;
  /* border:1px solid red; */
}

.search-form .btn {
  margin-top: 5px;
  background-color: #00d9a7;
  color: white;
}


.stats {
  margin-top: 20px;
}

.stats span {
  margin-right: 20px;
  font-size: 1rem;
}
.stats span img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.text_section {
  /* -webkit-backdrop-filter: blur(2px); */
  /* backdrop-filter: blur(2px); */
  background: var(--color-light);
  padding: 16px;
  /* border-color: var(--color-light); */
  border: 2px solid var(--secondary-color);
}

.text_section input {
  width: 218px;
  height: Hug (43px)px;
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 12px;
  ;

}

.text_section .property_select,
.text_section .property_search {
  width: 218px;
  height: Hug (43px)px;
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 12px;
  ;
}

.property_icons span {
  background: var(--color-light);
  border: 2px solid var(--secondary-color);
  width: Hug (236px)px;
  height: 52px;
  padding: 16px 24px 16px 24px;
  border-radius: 16px;
  ;

}

/* @media (max-width: 767.98px) { 
  .property_icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    padding: 4px 2px 4px 2px;
  }
  .property_icons .text_font {
      font-size: smaller;
      margin-top: 10px;
  }
  .stats span img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .property_icons span {
    height: 42px;
    padding: 8px;
    font-size: smaller;
  }
 } */

/* .form-control.padding{
  padding: 12px, 16px, 12px, 16px !important;
} */



@media only screen and (max-width: 320px) {
.property_icons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:10px;
}
.hero-title{
  font-size: 14px !important;
}
   
}
