body {

  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.bp-row {
  background-color: #002D3A;
  margin-top: 35PX;
}

.bp-row1 {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-left: 30px;
}

.bp-img1 {
  width: 19.2px;
  height: 19.2px;
  margin-right: 10px;
}

.bp-p1 {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: white;

}

.bp-buy-packages {
  padding: 10px 25px;
  background-color: #002D3A;
}

.bp-row {
  display: flex;
  flex-direction: column;
}

.bp-row1 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.bp-img1 {
  margin-right: 10px;
}

.bp-card-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0px 10px 10px 10px;
}

.bp-card {
  background-color: #003b4c;
  border-radius: 16px;
  overflow: hidden;
  /* padding: 20px; */
  width: 330px;
 min-height: 456px; 
  color: #e4e4e4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  position: relative;

}
.bp-card-cont{
  margin-top: 16px;
  padding:20px;
  /* border:1px solid red; */
}
.bp-shadow-line {
  /* position: absolute;
  top: 125px; */
  /* left: 0; */
  width: 100%;
  height: 0.1px;
  background-color: transparent;
  box-shadow: 0 1px 2px rgba(0, 219, 191, 0.3);
}

.bp-first {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  margin: 0;
  color: #00DBBF
}

.bp-card-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border-radius: 12px 12px 0 0; */
  padding:20px ;
  box-shadow: 0px 2px 10px 0px rgba(0, 219, 191, 0.15);
  align-items: center;
}

.bp-head {
  text-align: left;
  /* margin-left: 2px; */
  font-size: 14px;
  font-weight: 400;
  /* margin-top: 60px; */
  margin-bottom: 0;
  line-height: 19.12px;
  color: #00DBBF
}

.bp-p2 {
  font-size: 12px;
  line-height: 16.39PX;
  font-weight: 400;
  margin: 5px 0 0;
  text-align: center;
  color: var(--Text-White, rgba(255, 255, 255, 0.70));
  height:32px;
  /* border:1px solid red; */
}

.bp-boost-info {
  font-size: 12px;
  /* color: #90ee90; */
  color: #ffffffcc;

  line-height: 16.39px;
  font-weight: 400;
  margin: 5px;

}
.bp-heading{
  color: rgba(0, 219, 191, 1);
  font-size: 14px;
  font-weight: 600;

}
.bp-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bp-option input {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 0.1px groove rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  appearance: none;
}


.bp-option input:checked::before {
  content: '✔';
  font-size: 12px;
  color: #00DBBF;
  position: absolute;
  top: 0;
  left: 2px;
}

.bp-option span {
  font-size: 14px;
  display: inline-block;
  padding: 6px 10px;
  width: 100%;
  border: 0.1px groove rgb(0, 219, 191, 0.4);
  border-radius: 12px;
  background-color: #003b4c;
  color: white;
  text-align: left;
  cursor: pointer;
}


.bp-arrow img {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.bp-arrow {
  display: flex;
  /* margin-top:28px; */
  align-items: center;
}

.bp-arrow p {
  margin-top: 1px;
}


.bp-final {
  /* position: fixed;
  bottom: 0;
  left: 0; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #002D3A;
  padding: 20px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.bp-final1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003b4c;
  border-radius: 16px;
  padding: 0px 10px;
  width: 80%;
  max-width: 802px;
  height: 60px;
}

.bp-amount {
  font-size: 18px;
  font-weight: 500;
  color: #00DBBF;
  padding: 0;
  margin: 0;
}
.bp-toast {
  border: 1px solid #00DBBF;
  width: 200px;
  background-color: #00DBBF;
  height: 30px;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  /* padding: 2px; */
  margin: 0;
}
/* ------------------------ */
@media (max-width:1024px){
  .bp-card-container{
    display: flex;
  flex-wrap: wrap; 
 }
 .bp-card{
  flex: 0 1 calc(50% - 16px); /* Two cards per row */
 }
}
@media (max-width:426px){
  .bp-card{
    flex: 0 1 calc(100% - 26px); /* Two cards per row */
   }
}
@media (max-width:320px){
  .bp-card{
    flex: 0 1 calc(100% ); /* Two cards per row */
   }
}
