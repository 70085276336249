.list-sidebar {
    width:max-content;
    overflow: hidden;
    margin:  25px  ;
    border-radius: 30px;
    padding:16px;
    border: 1px solid var(--Stroke, #005771);
    background: rgb(59, 122, 232);
  }
  
  .list-sidebar h1 {
    font-size: 60px;
    color: white;

  }
  
  .list-sidebar .get-started {
    margin-top:20px;
    font-size: 30px;
    color: white;
    /* margin-bottom: 10px; */
    font-weight: bold;
  }
  
  .list-sidebar .rup {
    font-size: 90px;
    color: white;
    font-weight: bold;
    /* margin-bottom: 15px;  */
  }
  
  .list-sidebar p {
    font-size: 16px;
    color: white;
    justify-content: flex-end;
    /* text-align: end; */
    margin-left:200px;
    margin-top: 0;
  }
  
  .list-sidebar .buy-now {
    background-color: green;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    /* width:200px; */
    /* height:50px; */
    transition: background-color 0.3s ease;
  }
  
  .list-sidebar .buy-now:hover {
    background-color: #0056b3;
  }
  .chat-right-index{
    /* width:100%; */
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    border: 2px solid #00303E;
    background: #00252F;
    box-shadow: 0px 1px 4px 0px rgba(0, 85, 110, 0.16);
    margin: 0 25px ;
    padding:4px;
    gap:16px;
    justify-content: space-between;
}
.chat-right-index ul{width:100%;}
.nav-item button  {
  color:#00DBBF;
}

.nav-link[aria-selected="true"]:focus {
  background-color:#00DBBF; /* Background color */
  color: #00252F; /* Text color */
}

.nav-link[aria-selected="true"]:hover ,.nav-item button:hover{
  background-color: #00DBBF; /* Darker orange on hover for active state */
  color: rgb(0, 0, 0);

}

.chat-right-index p{
    display: flex;
    cursor: pointer;
    flex-direction: row;
    padding:8px 16px;
    align-items: center;
    justify-content: center;
}