Mylisting1.css

/* App Container */
.ml1-app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #0f1a2b;
  color: #fff;
}
.mylisting-add{
  width: 20%;

  /* height: 700px; */
}
.mylisting-add img{
width: 100%;
}
/* Main Content */
.ml1-content-container {
  display: flex;

  justify-content: space-between;
  padding: 20px;
  /* flex: 1; */
  gap:20px;
}

.mlhead {
  /* width: 500px; */
  /* padding: 20px; */
  display: flex;;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.mlhead p{
  color: #FFF;
/* font-family: Lato; */
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 1px;
margin: 0;
}
.back-btn {
  display: block;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  
}


.select-box {
  padding: 10px;
  background-color: #0f1a2b;
  color: #00DBBF;
  border: #00DBBF;
  border-radius: 15px;
  padding: 10px;
}

/* Listings */
.listings {
  flex: 2;
  max-width: 77%;
  /* padding:  0 20px; */
  /* background-color: #0f1a2b; */
}

.mlhead{
  display: flex;
  justify-content: space-between;
}


.ml1-listings-container {
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-top: 20px;
}

.ml1-listing-item {
  display: flex;
  flex-direction: row;
  gap:20px;
  background: #003D4EE5;
  padding: 15px;
  cursor: pointer;
  /* margin-bottom: 10px; */
  border-radius: 10px;
  border: 1px solid #005771
}

.ml1-listing-image {
  width: 210px;
  height: 230px;
  border-radius: 10px;
}

.ml1-listing-info {
  flex: 1;
  padding:8px 0;
}

.ml1-titleprice{
  display: flex;
  justify-content: space-between;
  color:#00DBBF;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
}
.ml1-left{
  display: flex;
  gap: 20px;
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}

.ml1-price{
  color: #00DBBF;
}

.ml1-locationscale{
  display: flex;
  gap: 40px;
}

.ml1-fhscale,
  .ml1-fhlocation{
  display: flex;
  gap: 10px;
  align-items: center;
}
.ml1-fhlocation p , .ml1-fhscale p{
  padding: 0;
  margin:0;
}
.ml1-locsca{
  width: 20px;
  height: 20px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  ;
  /* margin-top: 12px; */
}


.ml1-price {
  /* font-size: 18px; */
  /* font-weight: bold; */
  margin-top: 18px;
  width: Fill (190px)px;
  height: Hug (19px)px;
  gap: 16px;
  ;
  color: #00DBBF;
}

.ml1-locscadesact{
  display: flex;
  justify-content: space-between;
  gap: 16px;
}


.ml1-description{
  margin-top: 16px;
  color: #FFFFFFCC;
  /* font-family: lato; */
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
  width: 572px;
  height: 93px;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 0px;
  ;
}

.ml1-actions{
  display: flex;
  flex-direction: column;
  gap:16px;
  /* width: 100%; */
}

.ml1-mark-sold-btn,
.ml1-bump-btn,
.ml1-delete-btn {
  color: #00DBBF;
  background-color: #003D4EE5;
  /* margin-left: 60px; */
  /* border: none; */
  cursor: pointer;
  /* width: Fill (190px)px; */
  /* height: Fixed (38px)px; */
  /* padding: 8px 24px 8px 24px; */
  padding:8px 0;
  width:190px;
  /* gap: 20px; */
  border-radius: 12px;
  ;

}
.ml1-mark-sold-btn{
  color: #00DBBF;
  border-color: #00DBBF;
}

.ml1-bump-btn {
  color: #00DBBF;
  border-color: #00DBBF;
}

.ml1-delete-btn {
  color: #ef4444;
  border-color: #ef4444;
}

/* Advertisement */
.ml1-advertisement {
  width: 328px;
  height: 732px;
  top: 127px;
  left: 1090px;
  gap: 0px;
  border-radius: 30px;
  border: 1px 0px 0px 0px;
  ;
  margin-top: 40px;
  padding: 20px;
  background-color: rgb(12, 154, 241);
  color: #fff;
  text-align: center;
}
.ml1-locationscaledes{
  width: 100%;
  /* border:1px solid red; */
}
.ml1-addhead{
  text-align: left;
  size: 40px;
  margin-bottom: 60px;
}

.ml1-addpar{
  font-size: 50px;
  gap: 10px;
}

.ml1-addprice{
  text-align: left;
  margin-bottom: 60px;
}

.ml1-getstarted{
  font-size: 25px;
  /* font-weight: 100; */
  text-align: center;
  margin-bottom: 20px;
}

.ml1-addruppee{
  font-size: 60px;
  text-align: center;
}

.ml1-permonth{
  text-align: right;
  margin-top: 5px;
  font-size: 20px;
}

.ml1-buy-now-btn {
  font-size: 20px;
  padding: 20px 40px;
  background-color: #04c09e;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 60px;
}

/* Responsive Styles */
/* @media only screen and (min-width:320px) and (max-width:768px){
  .listings{
    max-width: 100%;
  }
  .mlhead p{
    font-size: 13px;
  }
  .mlhead{
    margin-left: -3px;
    display: flex;
    justify-content: space-between;
  }
} */
/* For devices with screen width up to 768px (tablets, small screens) */
@media (max-width: 768px) {
  .ml1-app-container{
    padding: 0 16px;
  }
.ml1-content-container {
  padding:16px;
  flex-direction: column; /* Stack the content vertically */
  align-items: center;
}

.mlhead{
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
}

.ml1-listings-container {
  align-items: center;
  justify-content: center;
  max-width: 100%; 

  /* padding: 0; */
}
.ml1-locationscaledes {
  height:max-content;
}

.ml1-locscadesact{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:0;
}


.ml1-listing-item {
  flex-direction: row; /* Stack image and text vertically */
  align-items: flex-start;
  padding: 10px;
  margin-left: 65px;
}

.ml1-listing-image {
  /* height:max-content; */
  /* margin-left: 19px; */
  height:280px;
  /* border:1px solid red; */

  margin-bottom: 10px;
}

.ml1-titleprice {
  flex-direction: row;
  text-align: center;
}

.ml1-locationscale {
  flex-direction: row;
  align-items: center;
}

.ml1-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  ;

}

.ml1-mark-sold-btn, .ml1-bump-btn, .ml1-delete-btn {
  width: 100%; /* Make buttons take up most of the width */
  margin: 5px 0;
  /* width: 190px; */
  /* height: 38px; */
  padding: 8px 24px 8px 24px;
  gap: 10px;
  border-radius: 12px ;
  ;

}
.ml1-advertisement{
  width:100%;
  padding:10px 30px;
  height:max-content;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.ml1-addhead , .ml1-addprice{
  margin-bottom:0;
}

.ml1-advertisement-1 {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; /* Full width on smaller screens */
}
.ml1-buy-now-btn{
  width:100%;
  padding: 10px;
}
}

/* For devices with screen width up to 480px (mobile phones) */
@media only screen and (min-width:320px) and (max-width:480px){
  .ml1-app-container {
    padding: 0 8px;
  }
  .listings{
    max-width: 100%;
  }
  .mlhead p{
    font-size: 13px;
  }
  .mlhead{
    margin-left: -3px;
    display: flex;
    justify-content: space-between;
  }
  .ml1-content-container {
    padding: 8px;
    flex-direction: column; 
    align-items: center;
  }



  .ml1-listings-container {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin-left: 65px;
  }

  .ml1-locationscaledes {
    height: auto;
  }

  .ml1-locationscale {
    display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 150px;
  }

  .ml1-locscadesact {
    flex-direction: column;
    align-items: center;
    gap: 0; 
  }

  .ml1-listing-item {
    flex-direction: column; 
    align-items: center; 
    padding: 8px; 
  }

  .ml1-listing-image {
    margin-right: 0;
    height: 200px; 
    border: 1px solid red;
    margin-bottom: 8px;
  }

  .ml1-titleprice {
    flex-direction: column; /* Stack title and price vertically */
    text-align: center;
  }


  .ml1-actions {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    justify-content: center;
    gap: 8px; /* Reduce gap between buttons */
    opacity: 1; /* Ensure buttons are visible */
  }

  .ml1-mark-sold-btn, .ml1-bump-btn, .ml1-delete-btn {
    width: 100%; /* Make buttons full-width */
    margin: 4px 0;
    padding: 8px 16px; /* Adjust padding for smaller screens */
    border-radius: 8px; /* Adjust button corner radius */
  }

  .ml1-advertisement {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px; /* Reduce the gap between advertisement elements */
  }

  .ml1-addhead, .ml1-addprice {
    margin-bottom: 0;
  }

  .ml1-advertisement-1 {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center;
    margin-top: 16px;
    width: 100%; /* Full width */
  }

  .ml1-buy-now-btn {
    width: 100%; /* Full width for button */
    padding: 8px;
  }
}

@media (max-width: 1024px) {
  .ml1-app-container{
    padding: 0 16px;
  }
.ml1-content-container {
  padding:16px;
  flex-direction: column; /* Stack the content vertically */
  align-items: center;
}




.ml1-listings-container {
  align-items: center;
  justify-content: center;
  max-width: 100%; 

  /* padding: 0; */
}
.ml1-locationscaledes {
  height:max-content;
}

.ml1-locscadesact{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:0;
}


.ml1-listing-item {
  flex-direction: row; /* Stack image and text vertically */
  align-items: flex-start;
  padding: 10px;
  /* margin-left: 65px; */
}

.ml1-listing-image {
  /* height:max-content; */
  /* margin-left: 19px; */
  height:280px;
  /* border:1px solid red; */

  margin-bottom: 10px;
}

.ml1-titleprice {
  flex-direction: row;
  text-align: center;
}

.ml1-locationscale {
  flex-direction: row;
  align-items: center;
}

.ml1-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  ;

}

.ml1-mark-sold-btn, .ml1-bump-btn, .ml1-delete-btn {
  width: 100%; /* Make buttons take up most of the width */
  margin: 5px 0;
  /* width: 190px; */
  /* height: 38px; */
  padding: 8px 24px 8px 24px;
  gap: 10px;
  border-radius: 12px ;
  ;

}
.ml1-advertisement{
  width:100%;
  padding:10px 30px;
  height:max-content;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.ml1-addhead , .ml1-addprice{
  margin-bottom:0;
}

.ml1-advertisement-1 {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; /* Full width on smaller screens */
}
.ml1-buy-now-btn{
  width:100%;
  padding: 10px;
}
}

@media (min-width:1025px) and (max-width:1280px) {
  .ml1-app-container{
    padding: 0 16px;
  }
.ml1-content-container {
  padding:16px;
  flex-direction: column; /* Stack the content vertically */
  align-items: center;
}

.mlhead{
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
}

.ml1-listings-container {
  align-items: center;
  justify-content: center;
  max-width: 100%; 

  /* padding: 0; */
}
.ml1-locationscaledes {
  height:max-content;
}

.ml1-locscadesact{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:0;
}


.ml1-listing-item {
  flex-direction: row; /* Stack image and text vertically */
  align-items: flex-start;
  padding: 10px;
  /* margin-left: 65px; */
}

.ml1-listing-image {
  /* height:max-content; */
  /* margin-left: 19px; */
  height:280px;
  /* border:1px solid red; */

  margin-bottom: 10px;
}

.ml1-titleprice {
  flex-direction: row;
  text-align: center;
}

.ml1-locationscale {
  flex-direction: row;
  align-items: center;
}

.ml1-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  ;

}

.ml1-mark-sold-btn, .ml1-bump-btn, .ml1-delete-btn {
  width: 100%; /* Make buttons take up most of the width */
  margin: 5px 0;
  /* width: 190px; */
  /* height: 38px; */
  padding: 8px 24px 8px 24px;
  gap: 10px;
  border-radius: 12px ;
  ;

}
.ml1-advertisement{
  width:100%;
  padding:10px 30px;
  height:max-content;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.ml1-addhead , .ml1-addprice{
  margin-bottom:0;
}

.ml1-advertisement-1 {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; /* Full width on smaller screens */
}
.ml1-buy-now-btn{
  width:100%;
  padding: 10px;
}
}

@media (min-width:1280px) and (max-width:1440px) {
  .ml1-app-container{
    padding: 0 16px;
  }
.ml1-content-container {
  padding:16px;
  flex-direction: column; /* Stack the content vertically */
  align-items: center;
}

.mlhead{
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
}

.ml1-listings-container {
  align-items: center;
  justify-content: center;
  max-width: 100%; 

  /* padding: 0; */
}
.ml1-locationscaledes {
  height:max-content;
}

.ml1-locscadesact{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:0;
}


.ml1-listing-item {
  flex-direction: row; /* Stack image and text vertically */
  align-items: flex-start;
  padding: 10px;
  /* margin-left: 65px; */
}

.ml1-listing-image {
  /* height:max-content; */
  /* margin-left: 19px; */
  height:280px;
  /* border:1px solid red; */

  margin-bottom: 10px;
}

.ml1-titleprice {
  flex-direction: row;
  text-align: center;
}

.ml1-locationscale {
  flex-direction: row;
  align-items: center;
}

.ml1-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  ;

}

.ml1-mark-sold-btn, .ml1-bump-btn, .ml1-delete-btn {
  width: 100%; /* Make buttons take up most of the width */
  margin: 5px 0;
  /* width: 190px; */
  /* height: 38px; */
  padding: 8px 24px 8px 24px;
  gap: 10px;
  border-radius: 12px ;
  ;

}
.ml1-advertisement{
  width:100%;
  padding:10px 30px;
  height:max-content;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.ml1-addhead , .ml1-addprice{
  margin-bottom:0;
}

.ml1-advertisement-1 {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; /* Full width on smaller screens */
}
.ml1-buy-now-btn{
  width:100%;
  padding: 10px;
}
}
