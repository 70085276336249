.total-container{
    background-color: #002d3a;
    padding: 20px 40px;
    /* display: flex; */
    width: auto;
}
.lpm-part-2-second-line .lpm-part-3-area-1{
  min-width: 255.25px;
}
.lpm-part-2-second .lpm-part-2-1{
min-width: 228px;
}
.lpm-part-2-first-line .lpm-part-2-1-allbuttons{
/* min-width: 440px; */
}
.lpm-part-2-second-line .lpm-part-2-third-line{
  /* min-width:510px; */
}
.lpm-part-3-area .lpm-part-3-area-1{
  width:50%;
}
.lpm-cont-full{
  display: flex;
  flex-direction: row;
  gap:20px;
  /* padding:20px; */
  /* border:1px solid red; */
  background-color:#002d3a;
}
.lpm-cont-full-2{
  width:30%;
}

.half-container{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

}
.checkbox-container{
display: grid;
grid-template-columns: repeat(5,19.5%);
  gap:10px;
  font-size: 18px;
}
.checkbox-label{
  width: 220px;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;    
  text-overflow: ellipsis; 
  cursor: pointer;
}
.checkbox-input{
  margin-left: 10px !important;
  width: 50px !important;
  height: 20px !important;

}
.checkbox-input:checked {
  background-color: #4caf50; /* Customize checked color */
  border-color: #4caf50;
}
.lpm-container{
    width: 100%;
    /* max-width: 1012px; */
    /* border: 1px solid #005771; */
    background-color: hsl(193, 100%, 15%);
    border-radius:  0 0 30px 30px;
    /* padding: 20px 0px; */
    gap: 16px;
    font-family: "Lato",sans-serif;
}

.lpm-part-1{
    border-bottom: 1px solid #005771;
    padding:0px 0px 24px 0px;
    text-align: center;

}


.lpm-part-1-head{
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 24px;
    color: white;
    margin-bottom: 16px;
}

.lpm-part-1-head span{
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 24px;
    font-style: italic;
    color: #00dbbf;
}

.lpm-part-1-allbuttons{
    display: flex;
    border: 2px solid #005771;
    padding: 16px;
    margin: auto;
    /* margin-left: 114px; */
    /* margin-right: 114px; */
    background-color: #002d3a;
    /* gap: 16px; */
    /* color: #00dbbf; */
    border-radius: 62px;
    justify-content: space-evenly;
    width: auto;
    max-width: 765px;
}

.lpm-part-1-btn{
    border-radius: 45px;
    padding: 10px;
    align-items: center;
    color: #00dbbf;
    background-color: #003b4c;
    border: none;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    pointer-events: auto;
}

.lpm-part-2{
  display: flex;
  flex-direction: column;
  gap:24px;
    padding: 0px 0px 24px 0px;
    border-bottom: 1px solid #005771;
}

.lpm-part-2-head{
    padding: 16px 24px;
    font-weight: 600;
    font-size: 18px;
    color: white;
}

.lpm-part-2-first-line{
    padding: 0px 24px;
    display: flex;
    color: white;
    font-weight: 400;
    font-size: 14px;
    gap: 15px;
}

.lpm-part-2-first{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.lpm-part-2-1-allbuttons{
    display: flex;
    border: 1px solid #26525f;
    border-radius: 12px;
    padding: 16px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* min-width: 510px; */
    gap: 13px;
    /* height: 56px; */
}
.lpm-part-2-1-allbuttons1{
  display: flex;
  border: 1px solid #26525f;
  border-radius: 12px;
  padding: 16px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* min-width: 510px; */
  gap: 13px;
  /* height: 56px; */
}
.lpm-part-2-1-1-allbuttons{
    display: flex;
    border: 1px solid #26525f;
    border-radius: 12px;
    padding: 16px;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    gap: 20px;
}

.lpm-part-2-1-btn{
    border-radius: 6px;
    padding: 4px 7px;
    background-color: #00485d;
    color: white;
    font-size: 12px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    /* height: 24px; */
}
.lpm-part-2-1-btn1{
  border-radius: 6px;
  padding: 4px 7px;
  background-color: #00485d;
  color: white;
  font-size: 12px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  /* height: 24px; */
}
.lpm-part-1-btn:active,
.lpm-part-1-btn:focus,
.lpm-part-2-1-btn:active,
.lpm-part-2-1-btn:focus ,
.lpm-part-2-1-btn1:active,
.lpm-part-2-1-btn1:focus ,
.lpm-part-2-1-1-btn:active,
.lpm-part-2-1-1-btn:focus,
.lpm-part-4-1-btn:active,
.lpm-part-4-1-btn:focus,
.lpm-part-4-1-dropdown:active,
.lpm-part-4-1-dropdown:focus{
  border:2px solid #00DBBF;
   /* Optional for extra visual effect */
}

.lpm-part-2-1-1-btn{
    /* display: flex; */
    border-radius: 6px;
    padding: 4px 7px;
    background-color: #00485d;
    color: white;
    font-size: 12px;
    font-weight: 400;
    width:100%;
    border: none;
    cursor: pointer;
}

.lpm-part-2-second-line{
    display: flex;
    /* justify-content: space-between; */
    /* align-items: first baseline; */
    padding: 0px 24px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    gap: 8px;
}

.lpm-part-2-second{
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.lpm-part-2-third-line{
    /* padding: 0px 24px; */
    color: white;
    font-size: 14px;
    font-weight: 400;
}

.lpm-part-3{
    padding: 0px 0px 24px 0px;
    border-bottom: 1px solid #005771;
    color: white;
}

.lpm-part-3 div{
    font-weight: 600;
    font-size: 16px;
}

.lpm-part-3 p{
    font-weight: 400;
    font-size: 14px;
}

.lpm-part-3-head{
    padding: 16px 24px 8px 24px;
}

.lpm-part-3-area{
    padding: 0px 24px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    /* width: 100%; */

}


.lpm-area-1{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #337184; 
}

.lpm-area-1 p{
    color: white;
}


.lpm-area-1-input select  {
    background-color:#00485d;
    border-radius: 6px;
    padding: 4px 7px;
    align-items: center;
    border: none;
    color: white;
    width:68px;
    margin-left: -74px;
}

.lpm-area-1-input-place{
   border: 1px solid #26525f;
   padding: 16px;
   border-radius: 12px; 
   background-color: transparent;
   width: 93%;
}

.lpm-part-4{
    border-bottom: 1px solid #005771;
    padding: 0px 0px 24px 0px;
    color: white;
}

.lpm-part-4-head{
    padding: 16px 24px 8px 24px;
    font-weight: 400;
    font-size: 16px;

}

.lpm-part-4-body{
    padding: 0px 24px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.lpm-part-4-1{
    width: 100%;
    font-size: 14px;
}

.lpm-part-4-1 p{
    align-items: center;
}

.lpm-part-4-1-allbuttons{
    display: flex;
    border: 1px solid #26525f;
    border-radius: 12px;
    padding: 16px;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    gap: 20px;
}

.lpm-part-4-1-btn{
    border-radius: 6px;
    padding: 4px 7px;
    background-color: #00485d;
    color: white;
    font-size: 12px;
    font-weight: 400;
    /* width:100%; */
    border: none;
    cursor: pointer;
}


.lpm-part-4-question-mark{
    width: 14px;
    height: 14px;
    color: #337184;
}
.lpm-part-4-1-allbuttonss {
    display: flex;
    gap: 20px;
    /* justify-content: center; */
    align-items: center;
    /* margin-top: 20px; */
    
  }
  
  .lpm-part-4-1-select {
    position: relative;
    display: inline-block;
  }
  .lpm-part-2-second-line .lpm-part-4-1-dropdown{
/* min-width: 310px;  */
 }
  .lpm-part-4-1-dropdown {
    padding: 10px 40px 10px 15px;
    border: 1px solid #26525f;
    border-radius: 12px;
    /* outline: 1.9px solid #00dbbf;  */
    background-color: #00485d;
    appearance: none; /* Removes default dropdown styling */
    cursor: pointer;
    font-size: 16px;
    color:white;
    width: 240px;
  }
  
  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #ffffffcc;
  }  
  .lpm-part-4-1-dropdown option {
    padding: 10px;
  }
  
.lpm-part-5{
    padding: 0px 0px 24px 0px;
    border-bottom: 1px solid #005771;
}

.lpm-part-5-head{
    padding: 16px 24px;
    color: white;
    font-weight: 600;
    font-size: 16px;
}

.lpm-part-5-data{
    padding: 0px 24px;
    font-size: 16px;
    color: white;
}

.lpm-part-5-expect{
    border: 1px solid #26525f;
    padding: 16px;
    border-radius: 12px;
    background-color: transparent;
    width: 96.5%;
}

.lpm-part-5-check{
    display: flex;
    padding: 16px 0px;
    gap:12px;
    font-size: 14px;
    line-height: 20px;
}

.lpm-part-6{
    /* border-bottom: 1px solid #005771; */
    padding: 0px 0px 24px 0px;
}

.lpm-part-6-1 p{
    padding: 16px 24px;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.lpm-part-6-upload-img{
    padding: 0px 24px;
    
}

.lpm-part-6-border{
    justify-content: center;
    align-items: center;
    gap: 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid #00dbbf;
    border-style: dashed;
    border-radius: 12px;
    height: 148px;
}

.lpm-part-6-image-icon{
    width: 42px;
    height: 42px;
    color: #00dbbf;
    border-radius: 12px; 
}

.lpm-part-6-border button{
    border: 1px solid #00dbbf;
    color: #00dbbf;
    background-color: transparent;
    padding: 4px 7px;
    border-radius: 8px;
    cursor: pointer;
}

.lpm-part-6-add-pics{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
}

.lpm-part-6-add-pics button{
    background-color: #00dbbf;
    height: 40px;
    color:#003d4e ;
    padding: 8px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.lpm-part-6-2{
    padding: 16px 24px;
    color: white;
    font-size: 14px;
}

.lpm-part-6-check-points{
    display: flex;
    align-items: baseline;
    gap: 10px;
    line-height: 25px;
    /* cursor: pointer; */
}


.lpm-part-6-final{
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 25px 0px;
}

.lpm-final-button{
    background-color: #00dbbf;
    color: #002d3a;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
}
.lpm-cont-full .active,.lpm-area-1-input input :focus{
  border:none;
  outline:none;
  border:1px solid #00dbbf;
  color:#00dbbf;
}

/* --------------------------------------------------------------------
advertisement */

.adds-list-sidebar h1 {
  font-size: 60px;
  color: white;
}

.adds-list-sidebar .adds-get-started {
  margin-top: 50px;
  font-size: 30px;
  color: white;
  font-weight: bold;
}

.adds-rup {
  font-size: 90px;
  color: white;
  font-weight: bold;
}

.adds-list-sidebar p {
  font-size: 16px;
  color: white;
  text-align: end;
  margin-top: 0;
}

.adds-list-sidebar {
  /* width:30%; */
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  /* margin-bottom: 15px; */
  background-color: rgb(12, 154, 241);
  border-radius: 10px;
  padding: 20px;
}

.adds-buy-now {
  background-color: green;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  padding: 16px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.adds-buy-now:hover {
  background-color: #0056b3;
}
.selected-files ul{
  list-style: none;
  display:grid;
  grid-template-columns: repeat(7,10%);
  flex-direction: row;
  gap: 12px;  
}
.selected-files ul li img{
  width: 78px;
  height: 78px;
}
/* --------------------------------------------------------------------------------------------------------------------- */

.lpm-pa2-container{
    width: 100%;
    /* height: 100%; */
    max-width: 328px;
    background-color:#003c4d;
    border: 1px solid #005771;
    border-radius: 30px;
    padding: 24px 0px;
    gap: 24px;
  }
  
  .pa2-head{
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
    color: white;
    padding: 0px 16px 16px 16px;
    border-bottom: 1px solid #005771;
    display: flex;
    justify-content: space-evenly;
  }
  
  .pa2-upi{
    padding: 16px 16px;
    /* margin-bottom: 10px; */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .pa2-upi-left, .pa2-upi-right{
    display: flex;
    gap: 10px;
  }
  
  .pa2-upi-right img{
    width: auto;
    height: 18px;
    
  }
  
  .pa2-input-1{
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #264c58;
    width: 94%;
    background-color: #004559;
  }
  
  .pa2-enter-upi{
    display: flex;
    align-items: center;
    padding: 0px 16px;
  }
  
  .pa2-enter-upi-img{
    color: white;
    margin-left: -22px;
  }
  
  .pa2-upi-left-2{
    padding: 16px 16px;
    display: flex;
    gap: 10px;
    color: white;
    letter-spacing: 1px;
  }
  
  .pa2-enter-card-main{
    align-items: center;
    padding: 0px 16px;
  }
  .pa2-enter-card{
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #264c58;
    width: 94%;
    background-color: #004559;
  }
  .pa2-icon{
    margin-left: -22px;
  }
  .pa2-cards{
    display: flex;
    padding: 20px 16px;
    gap: 10px;
  }
  
  .pa2-card-1{
    display: flex;
    align-items: center;
    color: white;
  }
  
  .pa2-card-input{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #264c58;
    width: 88%;
    background-color: #004559;
  }
  
  .pa2-amount{
    padding: 8px 22px;
    color: white;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  .pa2-amount-1{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    line-height: 20px;
    height: 30px;
  }
  
  .pa2-amount-2{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    line-height: 20px;
  }
  
  .pa2-proceed{
    padding: 15px 20px 5px 20px;
  }
  .pa2-proceed-btn{
    width: 100%;
    padding: 10px 24px;
    background-color: #00dbbf;
    border: none;
    color: #005771;
    font-size: 16px;
    /* font-weight: bold; */
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pa2-proceed-btn:hover {
    background-color: #00c48a;
  }


  /* -------------------------------------------------------------------------------------------------- */
  
  .pd-total{
    padding: 0px 16px;
  }

  .pd-head{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    color: white;
  }

  .pd-body{
    padding: 16px;
  }
  
  .pd-inner-body{
    width: 94%;
    display: flex;
    padding: 8px;
    border-radius:8px;
    gap: 12px;
    align-items: center;
    border: none;
    background-color: #004559;
  }

  .pd-img{
    width: 70px;
    height: 81px;
  }

  .pd-left{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .pd-inner-head{
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
  }

  .pd-inner-location, .pd-inner-area{
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color:#00dbbf ;
  }

/* ----------------------------------------------------- */
@media (max-width:768px){
 
  .lpm-part-2-1-allbuttons{
   display: flex;
   flex-wrap: wrap;
   gap:8px;
   justify-content:between;
  }
    
}
@media (max-width:426px){
  .sp-list-sidebar{
    display: none;
  }
  .sp-main-portion{
    width:100%;
  }
  .lpm-part-2-1-btn {
    height:24px;
    width:25px;
  }
  
  .lpm-part-2-1-allbuttons{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap:6px;
   justify-content: flex-start;
  }
.lpm-part-2-1-btn :nth-child(1),.lpm-part-2-1-btn :nth-child(2),.lpm-part-2-1-btn :nth-child(3){
  border:1px solid red
}
  .lpm-part-2-1-1-allbuttons,.lpm-part-3-area,.lpm-part-4-1-allbuttons,.lpm-part-4-1-allbuttonss{
  display: flex;
  flex-direction: column;
}
.lpm-part-6-2{
  flex-direction: column;
  gap:10px;
}
.lpm-part-6-check-points{
  /* height:60px; */
  overflow: hidden;
}
}
/* @media (max-width:320px){
  .lpm-part-6-2{
    display: none;
  }
} */

@media only screen and (max-width: 320px) {
  .lpm-part-2-1-allbuttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .lpm-part-2-1-allbuttons{
    width: 86%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .lpm-part-2-second{
    display: flex;
    flex-direction: column;
  }
  .lpm-area-1-input-place{
    width: 86%;
  }
  .lpm-part-2-1-btn {
    width: 100%;
  }
  .lpm-part-2-1-btn1 {
 width: 20%;
  }
  .lpm-part-4-1-dropdown {
    width: 100%;
  }
  .lpm-part-2-1-1-allbuttons{
    width: 44%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .checkbox-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sp-body-container{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width:321px) and (max-width:768px){
  .lpm-part-2-1-allbuttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .lpm-part-2-1-allbuttons{
    width: 86%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .lpm-part-2-second{
    display: flex;
    flex-direction: column;
  }
  .lpm-area-1-input-place{
    width: 86%;
  }
  .lpm-part-2-1-btn {
    width: 100%;
  }
  .lpm-part-2-1-btn1 {
 width: 20%;
  }
  .sp-left-content{
    width: 70%;
  }
  .lpm-part-4-1-dropdown {
    width: 100%;
  }
  .lpm-part-2-1-1-allbuttons{
    width: 44%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sf-right-cont{
    width: 100%;
  }
  .checkbox-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sp-body-container{
   
    display: flex;
    flex-direction: column;
  }


}