.lp-background {
  height: 100vh;
  background-image: url('../Assets/loginimg.png'); 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.lp-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
}

.lp-logo-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 20px;
  align-items: center;
}

.lp-logo {
  width: 300px;
  height: 200px; 
}

.lp-company-name {
  color: #fff;
  font-size: 24px;
  margin-top: 10px;
}

.lp-verification-box {
  background-color: rgba(0, 45, 58, 0.65);
  border-radius: 20px;
  padding: 30px 40px;
  width: 290px;
  color: #fff;
  margin-left: 20px;
  width:30%;
  height:max-content;
  z-index: 3;
}

.lp-h2 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1px;
  /* row-gap: 8px; */

}

.lp-p {
  font-size: 14px;
  color: #ccc;
}

.lp-input-container {
  display: flex;
  align-items: center;
  margin: 40px 0px 80px 0px;
}

.lp-phone-input{
  background-color: #003240;
  border-radius: 7px;
  margin-left: 5px;
  height: 40px;
  color: white;
  padding: 8px 10px; 
  box-sizing: border-box; 
  border: 1.52px solid var(--Stroke, #005771);
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.4);
}

.lp-country-select {
  display: flex;
  align-items: center;
  border: 1.52px solid var(--Stroke, #005771);
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.4);
  background: #003240;
  border-radius: 16px;
  margin-right: 5px;
  height: 40px; 
  padding: 10px 10px; 
  box-sizing: border-box; 
}

.lp-country-select .lp-flag-icon {
  width: 20px;
  margin-right: 8px;
}

.lp-arrow3 {
  width: 18px;
  height: 18px;
}

.lp-span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.lp-phone-input {
  flex: 1;
  border: none;
  border-radius:16px;
  outline: none;
  height: 40px; 
  padding: 8px 10px; 
  box-sizing: border-box; 
}

.lp-consent-note {
  font-size: 14px;
  margin-bottom: 20px;
  color: #ccc;
}

.lp-verification-button {
  width: 100%;
  padding: 10px;
  background-color: #00bfa6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.lp-verification-button:hover {
  background-color: #009f8c;
}
/* Error styling for OTP input boxes */
.vp-input-box.error-border {
  border: 1.5px solid red; /* Red border for incorrect OTP */
}

@media (max-width: 768px) {
  .lp-overlay {
    flex-direction: column;
    z-index: 2;
  }

  .lp-logo {
    width: 80%;
    height: auto;
    z-index: 3;
  }

  .lp-verification-box {
    width: 80%;
    padding: 20px;
    margin-top: 20px;
    z-index: 3;
  }

  .lp-input-container {
    flex-direction: column; 
    align-items: flex-start; 
  }

  .lp-input-container input {
    width: 100%; 
    margin-left: 0; 
    margin-bottom: 10px; 
  }

  .lp-country-select {
    width: 100%; 
    margin-bottom: 10px; 
  }

  .lp-verification-button {
    padding: 8px; 
  }
}

@media (max-width: 480px) {
  .lp-company-name {
    font-size: 20px; 
  }

  .lp-verification-box h2 {
    font-size: 18px;
  }

  .lp-verification-box p {
    font-size: 12px; 
  }

  .lp-consent-note {
    font-size: 10px;
  }

  .lp-verification-button {
    padding: 6px; 
  }
}