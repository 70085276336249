.villadetails-container{
    display: flex;
    flex-direction: column;
    padding: 0px 30px 0px 20px;

}
.villadetails-container2{
    display: flex;
    flex-direction:row;
    gap:30px;
    padding: 24px 0px 0px 0px;

}
.my-bg-secondary-1{
    background-color: transparent;
    /* border: 1px solid red; */
    padding: 0px 0px 0px 90px;
    display:flex;
    flex-direction: column;
    gap:20px;
    /* margin-bottom: 20%; */
}
.viewdetails-content{
    /* display: flex;
    flex-direction: row; */
    /* flex-wrap: wrap; */
    margin-bottom: -6%;

    gap: 20px;
    padding: 20px 0px 0px 20px;
    width: 100%;
}
.propertydetails-image{
    width: 99%;

}
.house-container{
    width: 100%;
    /* border: 1px solid red; */
}
.carousel-item .active{
    border: none !important;
}
.carousel-item img{
    /* border:1px solid red; */
    height: 100%;
    width:100%;
}
.googlemap-container{
    height: 350px;
    border-radius: 16px;
}
.property-card-text{
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  text-align: center;
  padding: 30px 10px;
    background:#00485D;
}
.property-card_sec {
    /* padding: 24px; */
    border-radius: 30px;
    border: 1px solid #00DBBF;

}

.property-card1 .layer {
    /* background: red; */
}

.property-card h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24.59px;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
}

.property-card>p {
    width: 103px;
    height: 19px;
    gap: 0px;
    ;
    color: #fff;
    font-size: small;


}

.property-card h3 {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.59px;
    letter-spacing: 1.2105263471603394px;
    text-align: center;

    color: #00d9a7;



}

.property-card h3 span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    letter-spacing: 1px;
    text-align: center;

}
.seller-image{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px !important;
    object-fit: cover;
    justify-content: center;
    /* border: 1px solid red; */
    padding-bottom: 20px;
}
.seller-image img{
    width:25%;
    height: 100%;
    object-fit: cover;
    justify-content: center;
    border: 2px solid #005771;
    border-radius: 12px;
}
.property-card iframe {
    width: 100%;
    min-height: 370px;
    gap: 0px;
    ;
    border-radius: 20px;

}

.property-card .owner-info {
    width: Fill (284px)px;
    height: Hug (162px)px;
    gap: 16px;
    ;

}

.property-card .owner-info p {
    min-width: 136px;
    height: 19px;
    gap: 0px;
    ;
    font-size: small;
    color: #fff;
    text-align: start;

}

.property-card .owner-info h5 {
    width: 135px;
    height: 25px;
    gap: 0px;
    ;
    color: #00d9a7;

}

.owner-info_cta {
    width: 100%;
}

.owner-info_cta p {
    color: #fff;
}

.owner-info_cta>button {
    width: Fill (134px)px;
    height: Hug (40px)px;
    padding: 10px 24px 10px 24px;
    gap: 10px;
    border-radius: 12px;
    border: 1px 0px 0px 0px;
    ;
    color: #000;
    border: 1px solid #00DBBF;
    background-color: #00DBBF;


}
.owner-info_cta .owner-btn:hover {
    border: 1px solid #00DBBF;
}
.float_section_opp {
    position: relative;
}
.float_section {
    padding: 36px 50px;
    position:relative;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: -120px;
}

.float_section .features {
    border: 0.97px solid #00DBBF;
    padding: 25px 50px;
    padding: 36px 50px;
    /* gap: 62.75px; */
    border-radius: 15.45px;

    ;
    color: #005771;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}
.chat-dots-btn:hover {
    background-color: #002d3a;
    border-color: var(--color-light);
    color: var(--primary-color);
  }
.float_section img {
    width: 30.89px;
    height: 30.89px;
    gap: 0px;
    border-radius: 3.86px 0px 0px 0px;
    ;

}
.float_section p {
    font-family: Manrope;
    font-size: 13.51px;
    font-weight: 400;
    line-height: 28.46px;
    letter-spacing: 0.9653430581092834px;
    text-align: center;
    color: #00DBBF;
    /* width: 122.79px; */
    height: 32px;
    text-align: center;
    gap: 0px;
    ;

}

.float_section h6 {
    font-family: Manrope;
    font-size: 17.38px;
    font-weight: 700;
    line-height: 23.74px;
    letter-spacing: 0.9653430581092834px;
    text-align: center;
    color: #FFFFFF;

    /* width: 122.79px; */
    height: 24px;
    gap: 0px;
    ;

}




/* ============= text styles */

.section-title {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.59px;
    letter-spacing: 1.2105263471603394px;
    text-align: left;
    
}
.details-row p, .amenities-row p {
    margin-bottom: 17px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    /* width: 343px; */
height: 19px;
gap: 0px;
;
color: #FFFFFF80;

}
.details-row p, .amenities-row img {
    width: 20px;
    height: 20px;
    border-radius: 2.5px 0px 0px 0px;
    margin-right: 9px;

}
.amenities-icon {
    font-size: 20px;
    display: inline-block;
    margin-right: 8px;
}

.description p{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 26.12px;
    text-align: left;
    color: #FFFFFF80;

}


.more-details .details_table {
    margin-left: 10px;
}
.more-details .details_table tr {
    height: 40px;
    /* background-color: red; */
}
.more-details .details_table th,
.more-details .details_table td
{
    /* background-color: red; */
    color: #FFFFFF80;

    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;

}
.vd-count-pics{
    display: flex;
padding: 16px var(--spacing-lg, 24px);
flex-direction: row;
align-items: center;
gap: 12px;
position: absolute;
left: 24px;
top: 24px;
border-radius: 16px;
background: rgba(0, 45, 58, 0.50);
backdrop-filter: blur(5px);
}
.vd-count-pics p{
    color: #FFF;
text-align: center;
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding:0;
margin:0
}


@media only screen and (max-width: 320px) {
    .villadetails-container{
        display: block;
        flex-direction: column;
        gap:10px
    }
    .float_section h6 {
        font-family: Manrope;
        font-size: 12px;
        font-weight: 700;
        line-height: 23.74px;
        letter-spacing: 0.9653430581092834px;
        text-align: center;
        color: #FFFFFF;
    
        /* width: 122.79px; */
        height: 24px;
        gap: 0px;
        ;
    
    }
    .carousel-item img{
        width: 100%;
        height: 1020px;

    }
    .float_section .features{
        display: grid;
        grid-template-columns: repeat(2,50%);
        width: 100%;
    }
    .villadetails-container2{
        display: flex;
        flex-direction: column;
        gap:20px
    }
    .property-card_sec{
        border: 10px solid red !important;
        margin-top: 300px;
    }
}

@media only screen and (min-width:321px) and (max-width:768px){
    .villadetails-container{
        display: block;
        flex-direction: column;
        gap:10px
    }
    .float_section h6 {
        /* font-family: Manrope; */
        font-size: 12px;
        font-weight: 700;
        line-height: 23.74px;
        letter-spacing: 0.9653430581092834px;
        text-align: center;
        color: #FFFFFF;
    
        /* width: 122.79px; */
        height: 24px;
        gap: 0px;
        ;
    
    }
    .viewdetails-content{
        margin-bottom: -19px;
    }
    .villadetails-container2{
        display: flex;
        flex-direction: column;
        gap:20px
    }
    .property-card_sec{
        width: 100%;
        margin-bottom: 40px;
    }

}

@media  (min-width:769px) and (max-width:1024px) {
    .villadetails-container{
        display: block;
        flex-direction: column;
        gap:10px
    }
    .float_section h6 {
        font-family: Manrope;
        font-size: 14px;
        font-weight: 700;
        line-height: 23.74px;
        letter-spacing: 0.9653430581092834px;
        text-align: center;
        color: #FFFFFF;
    
        /* width: 122.79px; */
        height: 24px;
        gap: 0px;
        ;
    
    }
    .viewdetails-content{
        width: 70% !important;
    }
    .villadetails-container2{
        display: flex;
        flex-direction:row;
        justify-content: space-between;
        gap:20px
    }
    .property-card_sec{
        width: 100%;
     
    }
}


@media (min-width:1025px) and (max-width:1280px) {
    .villadetails-container{
        display: block;
        flex-direction: column;
        gap:10px
    }
    .villadetails-container2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap:20px
    }

    .viewdetails-content{
        width: 70% !important;
    }
    .villadetails-container2{
        display: flex;
        flex-direction:row;
        justify-content: space-between;
        gap:20px
    }
  
}


@media  (min-width:1280px) and (max-width:1440px) {
    .villadetails-container{
        display: block;
        flex-direction: column;
        gap:10px
    }
    .villadetails-container2{
        display: flex;
        flex-direction: row;
        gap:20px
    }
    .property-card_sec{
        /* border: 10px solid red !important; */
        /* margin-top: 300px; */
    }
}