/* Profile container */
.profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    background-color: #122b40;
}

.popup-style{
    color:black;
     font-Size:14px;
      font-Weight:500;
       /* background:#00DBBF; */
       border-radius: 20px;
}
/* Left-side profile section */
.profile-left {
    width: 80%;
     display: flex;
     flex-direction: column;
     gap:30px;
    /* max-width: 70%; */
    /* flex: 1; */
    background-color: #122b40;
    border-radius: 10px;
    /* padding: 20px; */
    color: white;
    /* border: 1px solid red; */
}

/* Profile Image */
.profile-image-section {
    width: 100%;
    /* height: 90vh; */
    position: relative;
    text-align: center;
      border-radius: 30px;
      background-color: #ccc;
    /* border: 2px solid red; */
}

.carousel-item .profile-image {
    width: 700px;
    height: 644px; /* Changed to auto for responsiveness */
  
    opacity: 1; /* Set to 1 to make the image visible */
}
.no-image {
    /* background-color: transparent; */
    /* border:1px solid #00dbbfcc; */
    width: 350px;
    height:350px;
}
.photo-counter {
    position: absolute;
    top: 40px;
    left: 20px;
    color:black;
    border-radius: 12px;
    font-size: 16px;
    font-family: Manrope;
    /* width: 70px;
    height: 24px; */
    padding: 8px;
    text-align: center;
background: linear-gradient(91.36deg, #00C9FF 0.06%, #92FE9D 96.8%);


}

/* Personal Details */
.personal-details h2,
.description-section h2 {
    margin-bottom: 10px;
    color: white;
    font-size: 24px;
    
}

.personal-details p ,.description-section p 
    {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* border: solid red; */
    /* justify-content: space-between; */
    color: var(--Text-White, rgba(255, 255, 255, 0.70));
    font-family:Manrope;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
}
.personal-details  strong{
    color: var(--Button, #00DBBF);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    width:50%;

}

/* Description */


/* Right-side ad/map section */
.profile-right {
    background-color: #1c4966;
    border: 1px solid #005771;
    border-radius: 10px;
    padding: 20px;
    color: white;
    text-align: center;
    width: 27%; /* Changed to 100% for responsiveness */
    /* max-width: 250px; Max width to control size */
    height: 620px; /* Changed to auto for responsiveness */
}

/* Ad details */
.ad-details {
    margin-bottom: 20px;
}

.ad-details p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.ad-details .icon {
    margin-right: 5px;
}

/* Map iframe */
.map-section {
    margin-bottom: 20px;
}

/* Chat button */
.chat-button {
    background-color: #00a651;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-button:hover {
    background-color: #008540;
}

.action-item {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #003d4ee5;
    border: 1px solid #00dbbf;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 40px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.action-item h5 {
    font-size: 16px;
    margin: 8px;
}

/* Responsive Design */
@media (max-width: 780px) {
    .profile-container {
        flex-direction: column;
    }

    .profile-left, .profile-right {
        margin-bottom: 20px;
        width: 100%; /* Set to 100% for mobile */
    }

    .profile-right {
        max-width: none; /* Remove max-width for mobile */
    }
}

@media (max-width: 480px) {
    .personal-details h2,
    .description-section h2 {
        font-size: 20px; /* Reduce heading size for smaller screens */
    }

    .profile-image {
        border-radius: 20px; /* Adjust image border radius for smaller screens */
    }

    .chat-button {
        font-size: 16px; /* Adjust font size */
    }

    .action-item {
        font-size: 14px; /* Adjust action item font size */
    }
}
.chat-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5); /* Background dimming */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }