.navbar-main-cont{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px transparent inset; /* Set background to transparent */
    -webkit-text-fill-color: #ffffffcc; /* Change text color */
    border: none; /* Remove border */
}
.checkbtn {
    font-size: 30px;
    float: right;
    cursor: pointer;
    display: none;
    padding-right: 10px;
    /* border: 1px solid red; */
}
#check {
    margin-left: auto;
    display: none;
    /* border: 1px solid red; */
}
.navbar-content1 {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    border: 2px solid #FFFFFF26;
    margin: 20px;
    border-radius: 16px;
    padding: 10px 10px;
    background: transparent;
    color: white;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    background-color: #002D3A80;
    position: static;
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 0.15);
    background: rgba(0, 45, 58, 0.50);
    /* backdrop-filter: blur(5px); */
}
.nav-location{
    display: flex;
    flex-direction: column;
    gap:0 !important;
    padding: 0;
    margin: 0;
}
.nav-right-content1 {
    display: flex;
    align-items: center;
    gap: 24px;
}
.nav-right {
    display: flex;
    flex-direction: column;
    /* gap: 10px */
}
.fh-title{
    color: rgba(0, 219, 191, 1);
    font-size: 20px;
}
.nav-locate{
margin-left:-5px;

}
.nav-right-locate{
    display: flex;
    flex-direction: row;
    gap:5px
}
.location-around{
    color: rgba(0, 219, 191, 1);
    font-size: 18px;
    font-weight: 500;

}
.nav-right span {
    font-size: 16px;
    font-weight: 500;
    /* line-height: 18px; */
    text-align: center;
    color: #00DBBF;
    /* border: 1px solid white; */
    /* margin-top: 15px; */
}
.nav-left1 {
    display: flex;
    align-items: center;
    gap: 16px;
    /* margin-left: 48%; */
}
/* .nearbyIcon{
    position: absolute;
    left: 16%;
    bottom: 42%;
   
} */
.location-icon{
    width: 100% !important;
    height: 40px !important;
}
.location_select {
    cursor: pointer;
    border: none;
    color: #00DBBF;
    background: transparent;
    width: 80%;
    padding: 0px 10px 0px 10px;
    /* border: 1px solid red; */
    -webkit-appearance: none;
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* For Safari */
    background-image: url('../Assets/downarrow.svg'); /* Set your downarrow image path here */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position the arrow on the right */
    /* padding-right: 30px; Adjust padding to make room for the arrow */
    cursor: pointer;
}
.location_select option {
    background-color: #002D3A;
}
.btn {
    padding: 8px 24px 8px 24px;
    gap: 10px;
    border: 1px solid #00DBBF;
    /* color: #00DBBF; */
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
}
.btn:hover,.btn:active,.btn:focus{
    background-color: #00DBBF !important;
    color:black !important;
    border: 1px solid #00DBBF !important;
}

.btn-primary {
    padding: 8px 24px 8px 24px;
    background: #00DBBF;
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
}
.btn-primary:hover{
    background: #00DBBF;
}
.navProfile {
    display: flex;
    border: 1px solid #00DBBF;
    border-radius: 42px;
}
.navProfile img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 0px;
    gap: 5px;
    border-radius: 42px;
    background:#00DBBF;
    border: none;
    color: white;
}
.dropdown-menu {
    color: white;
    padding: 10px 10px;
    cursor: pointer;
    /* z-index: 999; */
    /* background: fixed transparent; */
    margin: 40px 0px 10px 0px !important;
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 0.15);
    background: rgba(0, 45, 58, 0.50);
    backdrop-filter: blur(5px);
}
.edit-profile {
    margin-top: 5px;
    margin-left: 25px;

}
.edit-profile:focus{
    background: #00DBBF;
}
.dropdown-profile {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    padding: 5px;
    /* border: 1px solid red; */
}
.dropdown-profile img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.dropdown-profile p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
}
.profile-items {
    margin-top: 20px;
}
.post-ad-icon,.list-prop-icon{
    display: none;
}
.dropdown-item {
    color: white;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
}
.dropdown-item:hover {
    background-color: #003B4C;
    color: white;
}
@media (min-width: 1024px){
    .navbar-content1 {
        /* width: 100%; */
        padding: 10px;
    }
    .nav-right-content1 {
        gap: 20px;
    }
    .nav-left1 {
        justify-content: space-between;
        /* padding: 15px; */
    }
    .nav-left1 .btn p{
        padding:0;
        margin:0;
    }
    .checkbtn {
        display: none; /* Hide checkbtn on larger screens */
    }
}
@media (min-width: 768px) and (max-width:1023px){
    .nav-left1{
        gap:12px;
    }
    .location_select{
        width:50%;
        padding: 0;
    }
    .nav-right-content1{
        gap:10px;
    }
    .nav-right{
    gap:4px;
    }
    .nav-left1 .btn p{
        display: none;
    }
    .post-ad-icon{
        display: block;
        border:none;
        width:38px;
        height:38px;
        padding:0;
        margin:0;
    }
    .list-prop-icon{
        display: block;
        padding:0;
        margin:0;
        height: 15px;
        width:20px;
    }
    .nav-left1 :nth-child(2){
        background-color: transparent;
        color:#00DBBF;
        padding:0;
        margin:0;
    }
    .nav-left1 :nth-child(3){
        background-color: #00DBBF;
        padding:10px;
        color: #002C38;
    }
}
/* @media (max-width: 320px) {
    .navbar-content1 {
        width: 93%;
        position: relative;
        flex-direction: row;
    }
    .nav-right-content1,
    .nav-left1 {
        flex-direction: row;
        gap: 10px;
    }
    .dropdown-menu {
        margin: 10px 0 !important;
    }
    .checkbtn {
        display: block;
    }
    .nav-left1 {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 60vh;
        top: 80px;
        left: -160%;
        background: #003746;
        justify-content: center;
        border-radius: 20px;
        transition: all 0.5s ease;
    }
    #check:checked ~ .nav-left1  {
        left: -54%;
    }
} */
@media (max-width: 767px)  and (min-width:320px){
    /* .navbar-content1 {
        flex-direction: row;
        width: 90%;
    }
    .nav-left1 {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 60px;
        left: -100%;
        background-color: #003746;
        transition: left 0.5s ease-in-out;
        justify-content: center;
        padding: 20px;
    }
    #check:checked ~ .nav-left1 {
        left: 0;
    }
    .nav-right-content1 {
        display: none;
    } */
.nav-right-content1 img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}
.location-around{
    /* opacity: 0; */
    display: none;
}
.form-control1{
    /* width: 50px; */
}
.nav-right-content1{
    gap:0px;
}
.nav-right img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}
.nav-left1 .btn{
padding:0;
margin: 0;
}
.post-ad-icon,.nav-left1 img,.list-prop-icon{
    display: block;
    border:none;
    width:28px;
    height:28px;
    padding:0;
    margin:0;
}
.list-prop-icon{
    height: 20px;
    width:20px;
}
.nav-left1 :nth-child(2),.nav-left1 :nth-child(3)
    {
    background-color: transparent;
    color:#00DBBF;
    border: none;
    padding:0;
    margin:0;
}
.nav-left1{
    gap:8px;
}
.nav-right{
    display:none;
}
.nav-left1 .dropdown{
    width:55px;
}
}