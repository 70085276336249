.vp-background {
    height: 100vh;
    background-image: url('../Assets/loginimg.png'); 
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.vp-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
}

.vp-logo-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center;
    margin-right: 20px;
}

.vp-logo2 {
    width: 300px;
    height: 200px;
}

.vp-company-name {
    color: #fff;
    font-size: 24px;
    margin-top: 10px;
}

.vp-verification-container {
    background-color: rgba(0, 45, 58, 0.65);
    border-radius: 20px;
    padding: 30px 40px;
    /* width: 290px; */
    color: #fff;
    margin-left: 20px;
    width:27%;
    z-index: 1;
    backdrop-filter: blur(35px);
}

.vp-h2 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
    line-height: 45px;
    color: #FFF;
/* font-family: Lato; */
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 48.792px; /* 152.475% */
}

.vp-p {
    font-size: 14px;
    font-weight: 250;
    width:75%;
    margin-bottom: 15px;
    color:#FFFFFFcc;
    color: var(--Text-White, rgba(255, 255, 255, 0.70));
/* font-family: Lato; */
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 27.446px; /* 152.475% */

}

.vp-edit-phone {
    color: white;
    font-size: 14px;
    font-weight: 250;
    margin-bottom: 20px;
    display: inline-block;
    cursor: pointer;
    color: var(--Text-White, rgba(255, 255, 255, 0.70));
/* font-family: Lato; */
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 27.439px; /* 152.439% */
text-decoration-line: underline;
}

.vp-input-group {
    display: flex;
    margin:15px 0 40px 0;    
    justify-content: space-between;
    /* align-content: center; */
    gap:12px;
}

.vp-input-box {
    width: 60px;
    height: 60px;
    background-color: #003240;
    border-radius: 12px;
    color: white;
    text-align: center;
    font-size: 24px;
    border: none;
    border: 1.5px solid var(--Stroke, #005771);
}

.vp-resend {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 250;
}

.vp-resend-link {
    color: white;
    cursor: pointer;
    align-items: center;
    font-weight: 350;
}


  /* Otp.css */

.vp-input-box {
    width: 50px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #ccc; /* Default border */
    border-radius: 5px;
    transition: border-color 0.3s ease; /* Smooth transition */
  }
  

  
  
@media (max-width: 768px) {
    .vp-overlay {
        flex-direction: column; 
        align-items: center; 
    }

    .vp-logo2 {
        width: 80%; 
        height: auto; 
    }

    .vp-verification-container {
        width: 80%; 
        padding: 20px; 
        margin: 10px 0;
        z-index: 1; 
    }

    .vp-input-box {
        width: 40px; 
        height: 40px; 
        font-size: 20px; 
    }
}

@media (max-width: 768px) {
    .vp-overlay {
        flex-direction: column; 
        align-items: center; 
    }

    .vp-verification-container {
        width: 290px; 
        padding: 20px; 
        margin-top: 10px; 
        z-index: 1; 
    }

    .vp-logo2 {
        width: 80%; 
        height: auto; 
    }

    .vp-input-box {
        width: 40px; 
        height: 40px; 
        font-size: 20px; 
    }
}

@media (max-width: 480px) {
    .vp-company-name {
        font-size: 20px; 
    }

    .vp-verification-container h2 {
        font-size: 18px; 
        line-height: 30px; 
    }

    .vp-verification-container p {
        font-size: 12px; 
    }

    .vp-edit-phone {
        font-size: 12px; 
    }

    .vp-resend {
        font-size: 10px; 
    }

    .vp-input-box {
        width: 35px; 
        height: 35px; 
        font-size: 18px; 
    }
}