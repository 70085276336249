.contact-container {
    background-color: #000021;
    color: #fff;
    font-family: 'Arial', sans-serif;
    padding: 0;

}

.contact-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
    margin-top: -375px;
}

.contact-header h1 {
    font-size: 54px;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: rgba(0, 61, 78, 1);
    font-family: "philosopher", sans-serif;

}

.contact-background-1 {
    width: 100%;
    height: 350px;
    gap: 0px;
    opacity: 15%;
}

.contact-image {
    height: 350px;
    gap: 0px;
    opacity: 0px;
}

/* Contact Section Styling */
.contact-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px;
    background-color: #0e0c24;
    /* Dark background */
    color: #fff;
}

.contact-left,
.contact-right {
    background: rgba(0, 61, 78, 1);
    padding: 30px;
    flex: 1;
    min-width: 300px;
    margin: 20px;
    border-radius: 20px;
}


.contact-left h2,
.contact-right h2 {
    color:rgba(0, 219, 191, 1);
    font-family: "philosopher", sans-serif;
}

.contact-left p,
.contact-details p {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    color: var(--Test-Dark-BG, #FFFFFFB2);

}

.contact-details p {
    margin: 10px 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    /* border: solid red; */

}

.contact-right form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
}

.contact-right form input,
.contact-right form textarea {
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #444;
    background: rgba(0, 61, 78, 1);

    color: #fff;

}

.contact-right form textarea {
    resize: none;
    height: 100px;
}

.contact-right form button {
    padding: 12px;
    background: rgba(0, 219, 191, 1);
    border: none;
    color:black;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.contact-right form button:hover {
    background: rgba(0, 219, 191, 1);

}

/* Map Container */
.map-container {
    margin-top: 40px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
}

.map-container iframe {
    border: 0;
    border-radius: 16px;
    width: 100%;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .contact-page {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-top: 0;
    }

    .contact-section {
        padding: 20px;
    }

    .contact-left,
    .contact-right {
        margin: 10px 0;
    }

    .contact-header h1 {
        font-size: 40px;
        text-align: center;
    }
}

@media (max-width: 768px) {

    .contact-left,
    .contact-right {
        flex: 1 1 100%;
        padding: 20px;
    }

    .contact-header h1 {
        font-size: 32px;
    }

    .contact-right form button {
        font-size: 14px;
        padding: 10px;
    }

    .map-container {
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .contact-container{
        display: flex;
        flex-direction: column;

    }
    .contact-header{
        display: flex;
        flex-direction: column;
    }
    .contact-section {
        flex-direction: column;
        padding: 10px;
       
    }

    .contact-left,
    .contact-right {
        padding: 15px;
        margin: 5px 0;
    }

    .contact-header h1 {
        font-size: 28px;
    }

    .contact-right form input,
    .contact-right form textarea {
        font-size: 14px;
        padding: 10px;
    }

    .contact-right form button {
        font-size: 14px;
        padding: 8px;
    }
}

.md-loc {
   display: flex;
   flex-direction:row;
   align-items: center;
   gap: 10px;
}
.md-loc p{
    margin-top:40px;
}