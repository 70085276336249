.favourites-container {
    display: grid;
    grid-template-columns: repeat(5,18.9%);
    gap:20px;
    /* grid-column-start: 1;
grid-column-end: 3; */
row-gap: 20px;
/* border: 1px solid red; */

  }
  .villa-card{
    width:100% !important; 
    /* width: 95%;
  }
  .villa-image{
    width: 100%;
    height: 207px !important;
    object-fit: cover;
    /* border: 1px solid red; */
  }
  .no-favourites{
    /* border:1px solid red; */
    height:50vh;
    display: flex;
    align-items: last baseline;
    justify-content: center;
  }

   

  @media only screen and (max-width: 320px) {
    .villa-card{
      width: 100% !important;
  
  }
  .favourites-container {
    display: grid;
    grid-template-columns: repeat(5,18.9%);
    gap:20px;
    /* grid-column-start: 1;
grid-column-end: 3; */
row-gap: 20px;
/* border: 1px solid red; */

  }
  }

  @media only screen and (min-width:321px) and (max-width:768px){
    .villa-card{
      width: 100% !important;
  
  }
  }

  @media  (min-width:769px) and (max-width:1024px) {
    .villa-card{
      width: 100% !important;
    }
  }