.invoice-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    height: 100vh;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .invoice-list {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 20px;
  }
  
  .invoice-card {
    border: 2px solid #005771;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(33% - 20px);
  }
  
  .document-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  
  .invoice-details {
    flex-grow: 1;
  }
  
  .invoice-number,
  .invoice-price {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
  
   
  }
  .invoice-price{
    color: #00dbbf;
  }
  
  .print-button {
    cursor: pointer;
    background-color: #005771;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .empty-invoice-container {
    text-align: center;
    padding: 50px 0;
  }
  .empty-invoice-container img {
    height: 100px;
    margin-bottom: 10px;
  }
  