
.pa-form {
    background-color: rgba(0, 45, 58, 0.7);
    border-radius: 30px;
    color: #fff;
    width: 45%;
    /* max-width: 328px; */
    /* height: 100%; */
    border: 1px solid #005771;
    border-radius: 30px;
border: 1px solid var(--Stroke, #005771);
background: rgba(0, 61, 78, 0.80);
backdrop-filter: blur(5px);
padding: 14px 0px 16px 0px;

  }
  .add-color{
    color:#00DBBF;
  }
  .pa-form-1 {
    padding: 20px 16px;
    display: flex;
    text-align: center;
    /* justify-content: center; */
    align-items: center;
    border-bottom: 1px solid #005771;
    
  }
  .lp-background2 {
    /* height: 100vh; */
    /* background-image: url('../Assets/loginimg.png');  */
    background-size: cover;
    background-position: center;
    display: flex;
    padding: 125px 70px 100px 70px ;

    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    gap:127px;
  }
  .pa-form-p{
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 16px;
  }

  
  .pa-form-ready {
    width:100%;
    /* border:1px solid red; */
    
    display: flex;
    justify-content: center;
    align-items: center;

    
    gap: 25px;
  }
  
  .pa-form-combine {
    display: flex;
    justify-content: space-between;
    padding-right: 60px;
  }
  
  .pa-divide-line {
    border: none;
    border-top: 1px solid #005771;
  }
  
  .pa-form-2 {
    padding: 16px;
    gap: 24px;
    
  }
  
  .pa-form-2-1 div {
    padding-top: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .pa-form-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
  }
  
  .pa-form-options input {
    margin-right: 10px;
  }
  
  .pa-form-2-2 p {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .pa-form-2-2 textarea {
    width: 93%;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px;
    font-family: 'Lato', sans-serif;
    resize: none;
    font-size: 14px;
  }
  
  
  .pa-form-2-3-1 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    gap: 20px;
    letter-spacing: 1px;
    /* justify-content: space-between; */
    align-items: center;
  }
  
  .pa-form-2-3 button {
    width: 100%;
    height: 100px;
    background-color: transparent;
    border: 2px dashed #00dbbf;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .pa-form-2-3 button img {
    width: 30px;
    height: 30px;
  }
  
  .pa-form-2-4 {
    padding: 12px 0px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
    gap: 10px;
  }
  
  .pa-form-2-3-1-1{
    font-size: 13px;
    font-weight: 200;
    
  }
  .pa-form-2-4 input{
    border: 1px solid #00dbbf;
    background-color: transparent;
  }
  
  .pa-form-2-4 p {
    font-size: 12px;
    font-weight: 400;
  }
  
  .pa-post-button {
    width: 100%;
    padding: 10px 24px;
    background-color: #00dbbf;
    border: none;
    color: #005771;
    font-size: 16px;
    /* font-weight: bold; */
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pa-post-button:hover {
    background-color: #00c48a;
  }

  .pa-form-options input[type="radio"]{
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #00dbbf;
    background-color: transparent;
    border-radius: 50%; /* For radio button */
    cursor: pointer;
    position: relative;
}

.pa-form-2-4 input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #00dbbf;
    background-color: transparent;
    border-radius:4px; /* For radio button */
    cursor: pointer;
    position: relative;
}
.pa-form-options input[type="radio"]:checked::before {
    content: '';
    /* display: block; */
    width: 8px;
    height: 8px;
    background-color: #00dbbf;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Ensures perfect centering */
}

/* Checkbox checked state: centers the checkmark */
.pa-form-2-4 input[type="checkbox"]:checked::before {
    content: '\2713'; /* Unicode checkmark */
    font-size: 12px;
    color: #00dbbf;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Ensures perfect centering */
}

/* Textarea border color on focus */
.pa-form-2-2 textarea:focus {
    outline: none;
    border: 2px solid #00dbbf;
    background-color: transparent;
}


.pa-form-2-3 button {
  width: 100%;
  height: 100px;
  background-color: transparent;
  border: 2px dashed #00dbbf;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative; /* Ensure positioning for label */
}

.pa-image-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #00dbbf; /* Border for the image button */
  border-radius: 10px;
  width: 98%; /* Adjust size as needed */
  height: 100px;
  cursor: pointer;
}

.uploaded-file-name {
  color: #00dbbf;
  margin-top: 5px; /* Adjust spacing as needed */
}

.back-arrow{
  left: 0;
}.pa-left-content{
  width: 100%;
  /* max-width: 785px; */
}
.pa-left-content h1 {
  font-size: 50px;
  color: #fff;
  /* width: 580px; */
}.pa-left-content h1 span {
  color: #00D4B5;
  font-style: italic; /* Highlighted 'Sell' text color */
}

@media only screen and (max-width: 320px) {
  .pa-left-content h1 {
    font-size: 18px;
    /* border: 1px solid red; */
    width: 200px;
 


  }
  .lp-options{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .pa-left-content{
    width: 100%
  }
  .lp-options button{
    width: 200px;

  }
  .lp-background2{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  }
  .pa-form{
    width: 250px;
  }
}

@media only screen and (min-width:321px) and (max-width:374px){
  .lp-background2{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  }
  .lp-options{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .lp-options button{
    width: 200px;

  }
  .pa-left-content h1 {
    font-size: 18px;
    /* border: 1px solid red; */
    width: 200px;
 
  }
  .pa-form{
    width: 250px;
  }
}
@media only screen and (min-width:375px) and (max-width:424px){
  .lp-background2{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  }
  /* .lp-background2{
    display: flex;
    flex-direction: row;
    gap:5px;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  } */
  .pa-left-content h1{
    font-size: 20px;
  }
  .lp-options{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .lp-options button{
    width: 200px;

  }
  .pa-left-content h1 {
    font-size: 18px;
    /* border: 1px solid red; */
    /* width: 200px; */
 
  }
  .pa-form{
    width: 250px;
  }
}
@media only screen and (min-width:425px) and (max-width:768px){
  .lp-background2{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  }
  /* .lp-background2{
    display: flex;
    flex-direction: row;
    gap:5px;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  } */
  .pa-left-content h1{
    font-size: 20px;
  }
  .lp-options{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .lp-options button{
    width: 324px;

  }
  .pa-left-content h1 {
    font-size: 18px;
    /* border: 1px solid red; */
    /* width: 200px; */
 
  }
  .pa-form{
    width: 300px;
  }
}
@media only screen and (min-width:769px) and (max-width:1024px){
  .lp-background2{
    display: flex;
    flex-direction: row;
    gap:5px;
    margin: 0;
    padding: 120px 20px 20px 40px;
   
  }
  .lp-options{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .lp-options button{
    width: 170px;

  }
  .pa-left-content h1 {
    font-size: 18px;
    /* border: 1px solid red; */
    /* width: 200px; */
 
  }
  .pa-form{
    width: 250px;
  }
}