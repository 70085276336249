/* Mylisting3.css */

.ml3{
  display: flex;
  margin: auto;
  padding: 10px 20px;
  /* background-color: #0f1a2b; */
}


.ml3-buy-packages {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  color: #e0f1f9;
}

.ml3-row {
  /* display: flex; */
  width: 100%;
  justify-content: space-between;
}

.ml3-row1 {
  display: flex;
  align-items: center;
  margin-bottom: 15px
}

.ml3-row1 img {
  margin-right: 10px;
}

.ml3-row1 p{
  margin-top: 15px;
}

.ml3-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.ml3-card {
  background-color: #0b313d;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px 12px 0px 0px;
background: rgba(0, 61, 78, 0.90);
/* box-shadow: 0px 2px 10px 0px rgba(0, 219, 191, 0.15); */
backdrop-filter: blur(5px);
height:480px;
}
.ml3-card-header{
  display: flex;
  height: 93px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px 12px 0px 0px;
background: rgba(0, 61, 78, 0.90);
/* box-shadow: 0px 2px 10px 0px rgba(0, 219, 191, 0.15); */
backdrop-filter: blur(5px);
}
.ml3-card-header :nth-child(2) {
  margin: 0;
  /* padding:10px; */
  /* margin-top: 24px;
  margin-bottom: 10px; */
  /* margin-left: 104px;
  margin-right: 104px; */
  /* color: white; */
  /* color: #00DBBF; */
  color: var(--Text-White, rgba(255, 255, 255, 0.70));
text-align: center;
font-family: Manrope;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
}
.ml3-first{
  color: var(--Button, #00DBBF);
text-align: center;
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
}

.mlupiidcard{
  margin-bottom: 14px;
  /* width: 300px; */
  /* height: 32px; */
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #27a6b9;
  /* background-color: #082a32; */
  color: #e0f1f9;
}

.ml3-card-details{
  display: flex;
  /* width: 300px; */
}

.ml3-first {
  font-size: 16px;
  font-weight: 700;
  color: #00DBBF;
  text-align: center;
  margin:0;
}

.ml3-shadow-line {
  margin: 10px 0;
  height: 10px; 
  background-color: rgba(21, 73, 89, 0.3); 
  margin-top: 20px;
}

.ml3headbro{
  margin-left: 104px;
  margin-right: 104px;
}

.ml3-head{
  /* margin-bottom: 10px; */
  /* text-align: center; */
  /* margin-left: 10px; */
  margin: 0;
  color: #00DBBF;color: var(--Button, #00DBBF);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.head {
  font-size: 14px;
  margin-top: 15px;
  /* color: #00DBBF; */
}

.ml3-arrow {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  color: white;
  /* padding: 10px; */
}

.ml3-arrow img {
  margin-right: 10px;
}

.ml3-boost-info {
  /* color: #8ad9ff; */
  /* text-align: center; */
  /* align-items: center; */
  margin-top: 16px;
  margin-bottom: 17px;
}

.ml3-option {
  display: flex;
  width: 100%;
  gap:10px;
  align-items: center;
  /* margin: 10px 34px; */
  padding:10px 0;
  /* margin-left: 104px;
  margin-right: 104px;  */
}


.ml3-option input[type="checkbox"] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 0.1px groove rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  appearance: none; 
}

.ml3-option input:checked::before {
  content: '✔'; 
  font-size: 12px;
  color: #00DBBF;
  position: absolute;
  top: 0;
  left: 2px;
}

.ml3optdayprice{
  border: 1px solid rgba(39, 166, 185, 0.1); 
  background: transparent;
  color: #fff;
  border-radius: 6px;
  width: 236px;
  /* gap: 12px; */
  /* text-align: center; */
  padding: 12px;
  /* gap: 10px; */
  border-radius: 12px;
  color: #FFF;
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
  ;
}

/* Property Details section */
.ml3propdetails {
  width:100%;
  /* border:1px solid red; */
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: 20px;
}

.ml3propdeta{
  /* font-family: Manrope; */
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  letter-spacing: 1px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 24px;
  margin-bottom: 16px;
}

.mlp31 {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  ;
  /* background: #004559; */
  border: 1px solid #264C58;
  margin-bottom: 40px;
}

.mlp3house1{
width: 50%;
align-items: center;
justify-content: center;
height: 170px;
object-fit: cover;
/* border: 1px solid red; */

}
.mlp3houseimg{
  width: 100%;
  height: 170px;
  border-radius: 10px;
  /* border: 1px solid red; */

}

.ml3locarea{
margin-left: 8px;
color: white;
}

.ml3mhankura{
  /* font-family: Manrope; */
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  letter-spacing: 1px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.ml3card1{
  /* width: Fill (296px)px; */
  height: Hug (87px)px;
  /* padding: 8px 0px 0px 0px; */
  gap: 12px;

}

.mlmhaloc{
  /* font-family: Manrope; */
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  letter-spacing: 1px;
  text-align: left;
  color: #FFFFFFCC;
  /* width: Fill (212px)px; */
  height: Hug (20px)px;
  gap: 8px;
  ;
}

.ml3loc{
  display: flex;
  margin-bottom: 8px;
}

.ml3loc img{
  width: 20px;
  height: 20px;
  gap: 0px;
  ;

}

.ml31loc img{
  width: 20px;
  height: 20px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  ;

}

.mlp32 {
  margin-top: 20px;
}


.ml3-payment-method-container {
  margin-top: 20px;
}

.mlpaymeth{
  align-items: left;
  margin-bottom: 16px;
  /* width: Fill (328px)px; */
  height: Hug (35px)px;
  padding: 8px 16px 8px 16px;
  gap: 16px;
  ;
  font-weight: 600;
  color: white;
}

.ml3-payment-option {
  margin: 10px 0;
}

.mlupi{
  /* width: 95px; */
  display: flex;
  margin-bottom: 16px;
}

.mlupip{
  margin-top: 20px;
  color: white;
}

.ml3-payment-icons{
  margin-top: 8px;
}

.mlupi3{
  display: flex;
  margin-bottom: 16px;
  /* gap: 20px; */
}

.ml3-payment-option label {
  display: flex;
  align-items: center;
}

.ml3-payment-icons img {
  margin-right: 10px;
  margin-top: 9px;
}

.ml3-input-field{
  background-color: #082a32;
  border: none;
}

.mlupiid {
  /* width: 300px; */
  /* height: 32px; */
  padding: 8px;
  margin-top: 16px;
  border-radius: 8px;
  gap: 12px;
  border: 1px solid #27a6b9;
  background-color: #082a32;
  color: #e0f1f9;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mlupiid img{
  width: 16px;
  height: 16px;
  padding: 1.02px;
  gap: 0px;
  opacity: 0.5px;
  margin-top: 8px;

}

.ml3-input-small {
  width: 45%;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #27a6b9;
  background-color: #082a32;
  color: #e0f1f9;
}

/* Price details section */

.mlpricedet3{
  margin-top: 24px;
  margin-bottom: 16px;
}

.mlpdhead3{
  /* font-family: Manrope; */
  font-size: 18px;
  font-weight: 500;
  line-height: 19.12px;
  letter-spacing: 1px;
  text-align: left;
  color: #FFFFFF;
}

.ml32pd1, .ml32pd2, .ml32pd3 {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  /* width: 340px; */
  height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;
  color: white;
}

.ml32pd3ot{
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  /* width: 340px; */
  height: 20px;
  margin-top: 28px;
  margin-bottom: 24px;
  color: white;
}

.ml32pd3 p {
  font-size: 18px;
  font-weight: 600;
  /* width: 340px; */
}

.ml3-ordertotal{
  font-weight: 600;
}

.ml3-proceedbtn {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  background-color: #27a6b9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.ml-lpm-pa2-container {
  width: 100%;
  /* height: 100%; */
  /* max-width: 328px; */
  background-color: #003c4d;
  border: 1px solid #005771;
  border-radius: 30px;
  padding: 24px 0px;
  gap: 24px;
}
.ml3-options{
  display: flex;
  flex-direction: column;
  height: 240px;
  overflow-y: auto;
}

.ml3-options::-webkit-scrollbar {
  width: 0; 
}



@media (max-width: 768px) and (min-width:481px) {
  .ml3{
    display: flex;
    flex-direction: column;
  }

  .ml3-card-container {
    grid-template-columns: 2fr;
  }
  .ml3-options{
    height: max-content;
  }
  .ml3-card{
    height: max-content;
  }
  .ml3optdayprice{
    width:65%;
  }
}

@media (max-width: 480px) and (min-width:320px){
  .ml3{
    display: flex;
    flex-direction: column;
  }
  .ml3-options{
    height: max-content;
  }
  .ml3-card-container {
    grid-template-columns: 1fr;
  }
  .ml3-card{
    height: max-content;
  }
  .ml3optdayprice{
    width:65%;
  }

}